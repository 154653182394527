import { nzTimeFromTimestamp } from '../time';
import { filterTimeseries,
  fetchOutage,
  getDataFieldsValues
} from './tools';
import { regExSuffix } from '../func';

export default async function loadTrainData(client, trainAsset) {
  const children = await client.assets.list({ filter: { parentExternalIds: [trainAsset.externalId] } })
  const childrenExternalIds = children.items.map(function(child) {return child.externalId})
  const childrenIds = children.items.map(function(child) {return child.id})

  const [directTimeseries, childrenTimeseries] = await Promise.all([
    client.timeseries.list({ filter: { assetExternalIds: [trainAsset.externalId] }}), 
    client.timeseries.list({ filter: { assetExternalIds: childrenExternalIds }})
  ]);
  const timeSeries = [...directTimeseries.items, ...childrenTimeseries.items];
  const currentFlowFilter = ts => ts.externalId.match(/^NZ_AFIC-\d{1,2}60\/A$/);
  const notAvailableFilter = ts => ts.externalId.match(regExSuffix('NOT_AVAILABLE'));

  const [currentFlowTs, notAvailableTs] = filterTimeseries(timeSeries, [
    currentFlowFilter,
    notAvailableFilter
  ]);

  const sequences = await client.sequences
      .list({
        filter: {
          assetIds: childrenIds.concat([trainAsset.id])
        }
      })
      .autoPagingToArray({ limit: Infinity });

  const instructionsSeq = sequences.filter(function (seq) {
    return seq.name.match(regExSuffix('INSTRUCTIONS'))
  })[0];
  const commentSeq = sequences.filter(function (seq) {
    return seq.name.match(regExSuffix('COMMENTS'))
  })[0];

  const outageEvent = await fetchOutage(client, trainAsset.id);

  const dataFields = {
    currentFlow: {
      ts: currentFlowTs
    },
    notAvailable: {
      ts: notAvailableTs
    },
    instructions: {
      sequence: instructionsSeq,
      columnExternalId: 'instruction'
    },
    comment: {
      sequence: commentSeq,
      columnExternalId: 'comment'
    },
    outage: {
      event: outageEvent,
      subtype: 'train outage'
    }
  };

  const values = await getDataFieldsValues(client, dataFields);

  return {
    id: trainAsset.id,
    master: {
      externalId: trainAsset.externalId,
      name: trainAsset.name.match(/\.([^.]*)$/)[1],
      currentFlow: values.currentFlow,
      notAvailable: values.notAvailable === 1,
      instructions: values.instructions[0],
      comment: values.comment[0],
      outageStartTime: outageEvent
        ? nzTimeFromTimestamp(outageEvent.startTime)
        : null,
      outageEndTime: outageEvent
        ? nzTimeFromTimestamp(outageEvent.endTime)
        : null
    },
    staging: {},
    dataFields
  };
}
