const tableColumn = (column, handleEdit) => {
  const onCell = (record) => {
    const allCells = (col) => ({
      title: col.title,
      dataIndex: col.dataIndex,
      className: `table-cell table-cell-${col.dataIndex.toLocaleLowerCase()}`,
      isArticle: col.isArticle
    });
    const editableCell = (col) => ({
      ...allCells(col),
      minNumber: col.minNumber,
      maxNumber: col.maxNumber,
      editable: col.editable,
      handleSave: col.handleEdit || handleEdit
    });
    return ({
      record,
      ...(column.editable
        ? editableCell(column)
        : allCells(column))
    })};
  const onHeaderCell = (record) => {
    return ({
      className: `table-cell table-cell-${record.dataIndex.toLocaleLowerCase()}`
    })};
  return {
    ...column,
    onHeaderCell,
    onCell
  };
};

export default tableColumn;