import {nowLocalTime, minutesSinceMidnight, startOfNzDay} from "../../time";
import {extractRateWithinTimestamp, calculateChangeIndication} from "./index";

export default function calculateTimeOfNextChange(dataSource) {
  let prevFlowRates = null;
  const flowProfile = [];
  const halfHoursSinceMidnight = Math.floor(minutesSinceMidnight() / 30);
  const halfHoursBeforeNextMidnight = 24 * 2 - halfHoursSinceMidnight;
  const timeOfNextChange = [];
  
  for (let i = 0; i < halfHoursBeforeNextMidnight; i += 1) {
    const mmt = startOfNzDay()
      .clone()
      .add(30 * halfHoursSinceMidnight + i * 30, 'minutes');
    
    let flowRates = [];
    const now = nowLocalTime();
    
    for(let j = 0; j < dataSource.length; j++) {
      const rate = extractRateWithinTimestamp(dataSource[j].wellRates, mmt.valueOf());
      flowRates.push({wellId: dataSource[j].id, average: rate});
    }
    
    if(mmt > now) {
      flowRates = calculateChangeIndication(flowRates, prevFlowRates);
      prevFlowRates = flowRates;
    } else {
      prevFlowRates = flowRates;
    }
    
    const cellOptions = {
      timestamp: mmt.format('HH:mm'),
      flowRates
    };
    
    flowProfile.push(cellOptions);
  }
  
  flowProfile.forEach(profile => {
    profile.flowRates.forEach(flowRate => {
      if(!timeOfNextChange.find(x => x.wellId === flowRate.wellId)) {
        if(flowRate.increase) {
          timeOfNextChange.push({wellId: flowRate.wellId, nextChange: {time: profile.timestamp, increase: flowRate.increase.toFixed(2)}});
        }
        else if(flowRate.decrease) {
          timeOfNextChange.push({wellId: flowRate.wellId, nextChange: {time: profile.timestamp, decrease: flowRate.decrease.toFixed(2)}});
        }
      }
    })
  });
  
  return timeOfNextChange;
}