import loadData from './loadData';
import configuration from '../configuration';
import { testConfig } from '../func';

function updateMasterData(oldRecord, newRecord) {
  if(!newRecord) {
    return oldRecord;
  }
  return {
    ...oldRecord,
    master: {
      ...newRecord.master
    }
  };
}

function findRecordById(records, id) {
  const matchingRecords = records.filter(record => record.id === id);
  return matchingRecords.length === 0 ? null : matchingRecords[0];
}

function mergeRecordSet(oldRecordSet, newRecordSet) {
  if(!oldRecordSet || !newRecordSet) {
    return [...oldRecordSet, ...newRecordSet ]; // no need to merge to empty record set
  }
  return newRecordSet.map(record => {
    const oldRecord = findRecordById(oldRecordSet, record.id);
    if (oldRecord) {
      return updateMasterData(oldRecord, record);
    }
    return record;
  });
}

const refreshDataFromCDF = async (client, oldWells, oldTrains, oldAggregates,
                                  handleFetchingError) => {
  const config = configuration();
  testConfig(config);
  const {
    wells,
    trains,
    aggregates
  } = await loadData(client, handleFetchingError, config);
  const newWells = mergeRecordSet(oldWells.items, wells);
  const newTrains = mergeRecordSet(oldTrains.items, trains);
  const newAggregates = updateMasterData(oldAggregates, aggregates);
  return { newWells, newTrains, newAggregates };
};

export default refreshDataFromCDF;