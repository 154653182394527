
function cloneRecord(record) {
  return {
    id: record.id,
    dataFields: record.dataFields,
    master: {
      ...record.master
    },
    staging: {
      ...record.staging
    }
  };
}
export default cloneRecord;