import {
  message
} from 'antd';

const handleChangedMasterValues = ({ changedAggregates, changedWells, changedTrains }) => {
  message.warn('Values have been updated since last run. Please review changes!', 5);
  if(changedAggregates.length) {
      console.info( 'Aggregates, have changed since last changes', changedAggregates);
    }
    if(changedWells.length) {
      console.info( 'Wells, have changed since last changes', changedWells);
    }
    if(changedTrains.length) {
      console.info( 'Trains, have changed since last changes', changedTrains);
    }
};
export default handleChangedMasterValues;