import mergeMasterStagingData from './mergeMasterStagingData';

const diffMasterValues = (
    {wells, trains, aggregates},
    { newWells, newTrains, newAggregates }) => {
    const diffCurrentToNewMaster = (currentState, newMaster) => {
      const data = {
        ...newMaster,
        master: newMaster.master,
        staging: currentState.master
      };
      const result = mergeMasterStagingData(data);
      return result.edited;
    };
    const findChangedValues = (current, newer) => {
      return newer.map(data => {
        const currentMatch = current.find(itm => itm.id === data.id);
        if( currentMatch ){
          const changed = diffCurrentToNewMaster(data, currentMatch);
          if (changed.length !== 0) {
            return { name: currentMatch.master.name, changed };
          }
        }
        return undefined;
      })
        .filter(keepIfDefined => keepIfDefined);
    };
    const changedAggregates = diffCurrentToNewMaster(aggregates, newAggregates);
    const changedWells = findChangedValues(wells.items, newWells);
    const changedTrains = findChangedValues(trains.items, newTrains);

    return ({
      changedAggregates, changedWells, changedTrains,
      changes: [changedAggregates, changedWells, changedTrains].some( group => !!group.length )
    })
  };

export default diffMasterValues;