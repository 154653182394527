import { Icon, notification } from 'antd';
import React from 'react';
import StatusDescription from './StatusDescription';


const progressConfig = (key, message) => ({
  key,
  placement: 'topRight',
  duration: 0,
  message,
  className: 'run-status-main'
});

const description = (props) => <StatusDescription {...props} />;

export const renderSaveProgress = (key, {stepDescriptions, message, currentStep, warning}, heading = 'Save and run') => {
  notification.open({
    ...progressConfig(key, heading),
    icon: <Icon type="loading" />,
    description: description({stepDescriptions, currentStep, message, warning})
  });
};

export const renderFinishedSaveProgress = (
  key,
  {
    stepDescriptions,
    currentStep = Infinity,
    message,
    status = 'success',
    warning = undefined
  },
  heading = 'Save and run'
) => {
  notification[status]({
    ...progressConfig(key, heading),
    icon: null,
    description: description({ stepDescriptions, currentStep, message, status, warning })
  });
};

export const renderFailedSaveProgress = (
  key,
  {stepDescriptions, message, currentStep, status = 'error'},
  heading = 'Save and run') => {
  notification[status]({
    ...progressConfig(key, heading),
    description: description({ stepDescriptions, currentStep, message, status })
  });
};
