import React from 'react';
import Images from '../../Images';
import { wellRatesTreshold } from '../../func';

const showIndicator = ({increase, decrease, average, current}) => {
  const diff = Math.abs(increase || decrease || 0);
  const validDiff = diff <= average * wellRatesTreshold();
  return !current || !validDiff;
};

const renderFlowRate = (flowRate) => {
  if(!flowRate) {
    return null;
  }
  const {increase, decrease, average} = flowRate;
  const faded = Number.isNaN(average) || !average ? ' low-opacity' : '';
  return(
    <span className={`value-wrap${faded}`}>
      {average != null && !Number.isNaN(average) && average.toFixed(2)}
      {!!increase && showIndicator(flowRate) && <Images.ArrowUpGreen className="smaller-arrow" alt="Arrow up" />}
      {!!decrease && showIndicator(flowRate) && <Images.ArrowDownRed className="smaller-arrow" alt="Arrow down" />}
    </span>
  );
};

export default renderFlowRate;