import React from 'react';
import { Table, Tag } from 'antd';
import renderUnit from './renderUnit';
import mergeMasterStagingData from '../func/mergeMasterStagingData';
import { Sm3s } from './units';
import { getTrainColor } from '../func';
import tableColumn from './cells/tableColumn';
import EditableCell from './cells/EditableCell';
import CheckboxField from './cells/CheckboxField';
import TimeRangePicker from './cells/TimeRangePicker';
import Text from './Text';
import Tab from './Tab';

function Trains(props) {
  // eslint-disable-next-line react/prop-types
  const { items, loading, handleEdit } = props;
  const dataSource = items.map(mergeMasterStagingData);
  dataSource.sort((a, b) => (a.name > b.name) ? 1 : -1);
  const columns = [
    {
      title: 'Train',
      dataIndex: 'name',
      width: '130px',
      render: (text, train) => (
        <Tag color={getTrainColor(train)} key={`name-${train.id}`}>
          {text}
        </Tag>
      )
    },
    {
      title: 'Not available',
      dataIndex: 'notAvailable',
      width: '85px',
      render: (checked, asset) => (
        <CheckboxField
          dataIndex='notAvailable'
          checked={checked}
          handleEdit={handleEdit}
          asset={asset}
        />
      )
    },
    {
      title: renderUnit('Current', Sm3s),
      dataIndex: 'currentFlow',
      width: '90px',
      render: (number) => (
        <span className='value-wrap'>
          {number != null && number.toFixed(2)}
        </span>
      )
    },
    {
      title: 'Instructions',
      dataIndex: 'instructions',
      editable: true,
      width: '25%',
      render: (text, asset) => <Text key={asset.id}>{text}</Text>
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      editable: true,
      width: '25%',
      render: (text, asset) => <Text key={asset.id}>{text}</Text>
    },
    {
      title: 'Outage',
      dataIndex: 'outageStartTime',
      render: (time, asset) => <TimeRangePicker asset={asset} handleEdit={handleEdit} />
    }
  ];

  const tableColumns = columns.map(col => tableColumn(col, handleEdit));
  return (
    <Table
      components={EditableCell.components}
      rowClassName={() => 'editable-row'}
      rowKey={record => record.id}
      pagination={false}
      loading={loading}
      dataSource={dataSource}
      columns={tableColumns}
      scroll={{y: 680}}
    />
  );
}

Trains.Tab = (props) => <Tab {...props} name='Trains' />;

export default Trains;
