import reduceDataFieldValues from './reduceDataFieldValues';
import reduceHistoricalFlowRates from './reduceHistoricalFlowRates';
import mergeMasterStagingData from '../../func/mergeMasterStagingData';
import getMissingOutageEndTime from './getMissingOutageEndTime';

const prepareRecordForPosting = (record) => {
  const mergedRecord = mergeMasterStagingData(record);
  const { historicalFlowRates, dataFields,
    wellRates,
    proposedRate,
    condensateRate,
    waterRate,
    outageEndTime,
    ...remaining } = mergedRecord;
  const {outageStartTime} = remaining;
  const reducedDataFields = reduceDataFieldValues(dataFields);
  const reducedHistoricalFlowRates = reduceHistoricalFlowRates(historicalFlowRates);
  return {
    ...remaining,
    historicalFlowRates: reducedHistoricalFlowRates,
    dataFields: reducedDataFields,
    ...getMissingOutageEndTime(outageStartTime, outageEndTime)
  }
};

export default prepareRecordForPosting;