import cloneRecord from './cloneRecord';
import mergeMasterStagingData from './mergeMasterStagingData';

export default function modifyRecords(records, id, dataIndex, value, constraintFunc = undefined ) {
  let modifiedAssetNumber = -1;
  let count = -1;
  const items = records.map((record) => {
    count++;
    if (record.id !== id) {
      return record;
    }
    const clonedRecord = cloneRecord(record);
    clonedRecord.staging[dataIndex] = value;
    if(constraintFunc) {
      clonedRecord.staging = {
        ...clonedRecord.staging,
        ...constraintFunc(mergeMasterStagingData(clonedRecord))
      };
    }
    modifiedAssetNumber = count;
    return clonedRecord;
  });
  return {items, modifiedAssetNumber};
}
