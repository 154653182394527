
export default function extractOptimalWellRates(wellRates, wellExternalId) {
  const values = [];
  wellRates.forEach(wellRate => {
    const { columns } = wellRate;
    const index = columns.findIndex(
      ({ externalId }) => externalId === wellExternalId
    );
    values.push({
      value: wellRate.values[index],
      rowNumber: wellRate.rowNumber
    });
  });
  return values;
}
