import mixpanel from 'mixpanel-browser';

const UNKNOWN_USER = "unknown_user"
const UNKNOWN_PROJECT = "unknown_project"
let _username =  UNKNOWN_USER;
let _project =  UNKNOWN_PROJECT;
let _mixpanel_set_up = false;

function _metadata() {
  return {project: _project, user: _username};
}

function track(event, props) {
  const props_to_write = {..._metadata(), ...(props || {})};

  if (_mixpanel_set_up)
    mixpanel.track(event, props_to_write);

  window._mp_log = (window._mp_log || []).concat([{
    event,
    props_to_write
  }]);

}

async function track_auth() {
  track("auth");
}

async function setup_mixpanel(cognite_client, config_getter) {
  try {
    const config = config_getter();
    _project = config.project;

    /* This token can be exposed publicly, no need to hide/mangle it */
    mixpanel.init('69fd6dcb6f5471405e5635feb04206fe', {debug: false});

    const new_username = (
      await cognite_client.get('/api/v1/token/inspect')
    ).data.subject || UNKNOWN_USER;
    _username = new_username;
    _mixpanel_set_up = true;

    track_auth();
  }
  catch (err) {
   window.setTimeout(() => setup_mixpanel(cognite_client), 5000);
  }
}

export { setup_mixpanel, track, track_auth };