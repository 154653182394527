import PropTypes from 'prop-types';

export const defaultProps = {
  disabled: undefined,
  difference: 0.0,
  diffTip: undefined,
  editing: false,
  fallbackValue: '',
  events: {
    onChange: undefined,
    onClick: undefined
  },
};

export const propTypes = {
  description: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  difference: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  diffTip: PropTypes.string,
  editing: PropTypes.bool,
  fallbackValue: PropTypes.string,
  events: PropTypes.shape({
    onChange: PropTypes.func,
    onClick: PropTypes.func
  })
};