import { CogniteClient} from '@cognite/sdk';
import {  PublicClientApplication } from "@azure/msal-browser";

const baseUrl = 'https://omv.cognitedata.com'
const scopes = [
  `${baseUrl}/DATA.VIEW`,
  `${baseUrl}/IDENTITY`,
  `${baseUrl}/user_impersonation`
];

export const appConfig = () => ({
  appId: 'OMV Flow Rates app',
  project: 'omv',
  configExternalId: 'flow_rates_application_config',
  baseUrl: 'https://omv.cognitedata.com'
});

const config = {
  auth: {
    clientId: "1d567675-b14b-4a46-9fdf-b3801ef729ab",
    authority: "https://login.microsoftonline.com/a8f2ac6f-681f-4361-b51f-c85d86014a17",
  },
};

export const pca = new PublicClientApplication(config);


export const getToken = async () => {
  const accountId = sessionStorage.getItem("account");
  const account = pca.getAccountByLocalId(accountId);
  let token = ""
  if (!account) {
    token = await pca.acquireTokenPopup({
      account,
      scopes,
    });
  } else {//throw new Error("no user found");
    token = await pca.acquireTokenSilent({
      account,
      scopes,
    }).catch(e => {
      return pca.acquireTokenPopup({
        account,
        scopes,
      });
    });
  }

  return token.accessToken;
};

export const logIn = async () => {
  const { project, appId, baseUrl } = appConfig();
  const client = new CogniteClient({
    project: project,
    appId: appId,
    baseUrl: baseUrl,
    getToken
  });

  await client.authenticate();
  return client
};

const fetchBaseConfig = async (client) => {
  const externalId = appConfig().configExternalId;
  const url = await client.files.getDownloadUrls([{ externalId }]); //await
  const baseUrl = url[0].downloadUrl;
  return {
    ...(
      await fetch(
        baseUrl,
        { method: 'GET' }
      ).then(response => response.json())
    ),
    ...appConfig()
  };
};

const fetchModelParameters = async (client, baseConfig, configAmendmentId = 'modelParametersExternalId') => {
  const externalId = baseConfig[configAmendmentId];
  if (!externalId) {
    console.warn(`No such externalId: ${configAmendmentId}`);
    return {}
  }
  try {
    const url = await client.files.getDownloadUrls([{ externalId }]);
    const baseUrl = url[0].downloadUrl;
    const body = await fetch(
      baseUrl,
      { method: 'GET' }
    ).then(response => response.json());
    return body;
  } catch (e) {
    console.warn(e.message);
    return undefined;
  }
};

export const initConfiguration = async (client = undefined) => {
  if (client) {
    const baseConfig = await fetchBaseConfig(client);
    const modelParameters = await fetchModelParameters(client, baseConfig);
    window.globalState.config = { baseConfig, modelParameters };
  }
};

const configuration = () => {
  if (!window.globalState.config) return undefined;
  const { baseConfig, modelParameters } = window.globalState.config;
  return baseConfig && {
    ...baseConfig,
    modelParameters: {
      ...baseConfig.modelParameters,
      ...modelParameters
    }
  };
};

export default configuration;
