function mergeMasterStagingData(data) {
  const merged = {
    id: data.id,
    dataFields: data.dataFields,
    edited: []
  };

  const isPrimitive = test => test !== Object(test);
  const isArray = test => Array.isArray(test);
  const isSameInJson = (a, b) => JSON.stringify(a) === JSON.stringify(b);
  const isEqual = (a, b) => {
    if (isPrimitive(a)) {
      return a === b || isSameInJson(a, b); // Treat NaN values as 'Null'
    }
    if (isArray(a)) {
      return isSameInJson(a, b);
    }
    return a.isSame(b); // momentjs
  };
  if(!data.staging ) {
    return data; // no need to run
  }
  Object.keys(data.master).forEach(key => {
    const masterValue = data.master[key];
    const stagingValue = data.staging[key];
    if (stagingValue !== undefined && !isEqual(stagingValue, masterValue)) {
      merged[key] = stagingValue;
      merged.edited.push(key);
    } else {
      merged[key] = masterValue;
    }
  });
  return merged;
}
export default mergeMasterStagingData;