import React from 'react';
import { node } from 'prop-types';

const CellArticle = ({title, children}) => (
  <>
    {title ? <h4>{title.props ? title.props.children[0] : title}</h4> : null}
    {children}
    {title && title.props ? title.props.children[1] : ''}
  </>
);
CellArticle.defaultProps = {
  title: undefined,
  children: null
};
CellArticle.propTypes = {
  title: node,
  children: node
};

export default CellArticle;