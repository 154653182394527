export default function calculateChangeIndication(currentFlowRates, prevFlowRates) {
  const flowRates = [...currentFlowRates];
  
  if (prevFlowRates) {
    for (let i = 0; i < currentFlowRates.length; i++) {
      const current = currentFlowRates[i].average || NaN;
      const previous = prevFlowRates[i] ? prevFlowRates[i].average : NaN;
      if (Number.isNaN(current)) {
        if (!Number.isNaN(previous)) {
          flowRates[i].decrease = previous;
        }
      } else if (Number.isNaN(previous)) {
        flowRates[i].increase = current;
      } else if (current.toFixed(2) > previous.toFixed(2)) {
        flowRates[i].increase = current.toFixed(2) - previous.toFixed(2);
      } else if (current.toFixed(2) < previous.toFixed(2)) {
        flowRates[i].decrease = current.toFixed(2) - previous.toFixed(2);
      }
    }
  } else {
    flowRates.forEach((flowRate) => {
      if(!Number.isNaN(flowRate.average)) {
        flowRate.increase = flowRate.average;
      }
    })
  }
  
  return flowRates
}

