import {
  nowNzTime
} from '../time';
import { fetchLatestDatapoint, fetchLatestDatapointValue, getDataFieldsValues } from './tools';
import {aggregates} from '../func';
import configuration from '../configuration';

async function getDailyProductionSoFar(client, nowTime) {
  const ts = await client.timeseries.retrieve([
    { externalId: 'NZ_SFQI-1401A' }
  ]);
  const ageLimit = configuration().aggregatesAgeLimitMinutes;
  const [dailyProductionSoFar] = await fetchLatestDatapoint(client, ts, nowTime);
  const numberOfMinutesSinceLastDataPoint = (nowTime
    - dailyProductionSoFar.timestamp.getTime())
    / 60000;
  if( numberOfMinutesSinceLastDataPoint > ageLimit) {
    const error = new Error(
      `The the last daily production point is more than ${ageLimit} minutes old`
    );
    error.data = {
      message: `Latest timestamp: ${dailyProductionSoFar.timestamp}`,
      action: 'stopDisplayingAllSensorData'
    };
    throw error;
  }
  return dailyProductionSoFar.value;
}

async function getCurrentRate(client, nowTime) {
  const ts = await client.timeseries.retrieve([
    { externalId: 'NZ_SFI-1401A' }
  ]);
  return (await fetchLatestDatapointValue(client, ts, nowTime))[0];
}

async function fetchTimeSeries(client) {
  const [
    dailyNominationTs,
    gncTs,
    weldedPointImbalanceTs
  ] = await client.timeseries.retrieve([
    { externalId: 'NZ_S-Oaonui-GAS.GAS_DAY.NOMINATIONS' },
    { externalId: 'NZ_S-Oaonui-GAS.GAS_DAY.GNC' },
    { externalId: 'NZ_S-Oaonui-GAS.GAS_DAY.IMBALANCE' }
  ]);

  const dataFields = {
    dailyNomination: {
      ts: dailyNominationTs
    },
    gnc: {
      ts: gncTs
    },
    weldedPointImbalance: {
      ts: weldedPointImbalanceTs
    }
  };
  const values = await getDataFieldsValues(client, dataFields);

  return {values, dataFields};
}

async function fetchFuel(client) {

  const lastDayAvgArgs = {
    start: '1d-ago',
    end: 'now',
    aggregates: ['average'],
    granularity: '1d'
  };
  const fuelDatapoints = await client.datapoints.retrieve({
    items: [
      {
        externalId: 'NZ_SFT-2501A',
        ...lastDayAvgArgs
      },
      {
        externalId: 'NZ_AFT-4020A',
        ...lastDayAvgArgs
      }
    ]
  });
  return (fuelDatapoints[0].datapoints[0].average +
      fuelDatapoints[1].datapoints[0].average) /
    0.293;
}

async function loadAggregations(client, wells) {
  const now = nowNzTime();
  const [
    {values, dataFields},
    fuel,
    dailyProductionSoFar,
    currentRate
  ] = await Promise.all([
    fetchTimeSeries(client),
    fetchFuel(client),
    getDailyProductionSoFar(client, now.valueOf()),
    getCurrentRate(client, now.valueOf())
  ]);
  const scheduledQuantity = values.dailyNomination + values.gnc;

  return aggregates({dataFields}, wells, scheduledQuantity,
    {
      ...values,
      fuel,
      dailyProductionSoFar,
      currentRate
    });

}

export default loadAggregations;