import { minutesInADay, minutesSinceMidnight as msm, startOfNzDay } from '../../time';
import calculateWeightedSum from './calculateWeightedSum';
import removeFromStagingWells from './removeFromStagingWells';
import { getRateFromTimestamp } from '../rates';

const toNumber = (value) => typeof value === 'string' ? Number.parseFloat(value) : value;

function calculateProposed(fuel, dailyProductionSoFar, weightedSum, minutesSinceMidnight) {
  if (weightedSum > 0.0) {
    return (
      dailyProductionSoFar
      + weightedSum / 0.293
      - fuel * (1 - (minutesSinceMidnight / minutesInADay))
    );
  }
  return  dailyProductionSoFar;
}

function calculateCurrent(dailyProductionSoFar, currentRate, minutesSinceMidnight) {
  return dailyProductionSoFar + (currentRate/0.293) * (1 - minutesSinceMidnight/1440);
}

function calculateRequiredRate(scheduledQuantity, dailyProductionSoFar, minutesSinceMidnight) {
  return minutesSinceMidnight !== minutesInADay
    ? 0.293 * (scheduledQuantity - dailyProductionSoFar) * (minutesInADay / (minutesInADay - minutesSinceMidnight))
    : NaN;
}

function calculateCurrentCondensate(wells) {
  const condensateRates = wells.map(well => well.master.condensateRate)
  return condensateRates.reduce((a, b) => a + (b || 0) , 0);
}

function calculateOptimizedCondensate(wells) {
  const now = Date.now();
  const millicubesPerHour = 277.78;

  const proposedCondensateRates = wells.map(well => {
      const {wellRates, cgr} = well.master;
      const proposedRate = getRateFromTimestamp(wellRates, now);
      return (proposedRate * cgr) / millicubesPerHour;
    }
  )
  return proposedCondensateRates.reduce((a, b) => a + (b || 0) , 0);
}

const toFixed = (value) => {
  return value.toFixed(2);
};
function aggregates(
  {
    master,
    dataFields,
    staging = {}
  },
  wells,
  scheduledQuantity = undefined,
  fetchedValues = undefined
) {
  const values = () => {
    if (!fetchedValues) return {};
    const {
      fuel,
      dailyProductionSoFar,
      currentRate,
      dailyNomination,
      gnc,
      weldedPointImbalance
    } = fetchedValues;
    return {
      fuel: toFixed(fuel),
      dailyProductionSoFar: toFixed(dailyProductionSoFar),
      currentRate: toFixed(currentRate),
      dailyNomination: toFixed(dailyNomination),
      gnc: toFixed(gnc),
      weldedPointImbalance: toFixed(weldedPointImbalance)
    };
  };

  const calculated = () => {
    if (!fetchedValues && !master) return {};
    const {
      fuel,
      dailyProductionSoFar,
      currentRate
    } = fetchedValues || master;
    const fuelF = toNumber(fuel);
    const dpsF = toNumber(dailyProductionSoFar);
    const crF = toNumber(currentRate)
    // ?? since 0 is a valid value and should not be evaluated to false
    const sq = scheduledQuantity ?? toNumber((!!staging && staging.scheduledQuantity) || master.scheduledQuantity);

    const startOfDay = startOfNzDay();
    const minutesSinceMidnight = msm();
    const weightedSum = calculateWeightedSum(
      wells.map((well) => ({
        name: well.master.name,
        wellRates: well.staging.wellRates || well.master.wellRates
      })),
      startOfDay,
      minutesSinceMidnight
    );
    const forecastProposed = calculateProposed(fuelF, dpsF, weightedSum, minutesSinceMidnight);
    const forecastCurrentRate = calculateCurrent(dpsF, crF, minutesSinceMidnight);
    const requiredRate = calculateRequiredRate(sq, dpsF, minutesSinceMidnight);

    const currentCondensate = calculateCurrentCondensate(wells);
    const optimizedCondensate = calculateOptimizedCondensate(wells);

    return {
      requiredRate: toFixed(requiredRate),
      forecastProposed: toFixed(forecastProposed),
      forecastCurrentRate: toFixed(forecastCurrentRate),
      currentCondensate: toFixed(currentCondensate),
      optimizedCondensate: toFixed(optimizedCondensate)
    };
  };

  const scheduledQt = () => {
    return scheduledQuantity >= 0 ? { scheduledQuantity: toFixed(scheduledQuantity) } : undefined;
  };

  return {
    master: {
      ...master,
      ...values(),
      ...calculated(),
      ...scheduledQt()
    },
    staging,
    dataFields
  };
}

aggregates.reset = prev => {
  const { items, ...restWells } = prev.wells;
  const { staging, ...rest } = prev.aggregates;
  const wellItems = removeFromStagingWells(items, 'wellRates');
  return {
    wells: {
      ...restWells,
      items: wellItems
    },
    aggregates: aggregates( rest, wellItems)
  };
};

export default aggregates;