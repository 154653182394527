import React from 'react';
import { Table, Tag, Tooltip } from 'antd';
import renderUnit from './renderUnit';
import filterPrepareAndSortWells from '../func/filterPrepareAndSortWells';
import mergeMasterStagingData from '../func/mergeMasterStagingData';
import {calculateTimeOfNextChange} from '../func/rates';
import { getWellColor, wellRatesTreshold } from '../func';
import { Sm3s } from './units';
import tableColumn from './cells/tableColumn';
import EditableCell from './cells/EditableCell';
import CheckboxField from './cells/CheckboxField';
import Images from '../Images';
import nextChange from './timeOfNextChange';
import TimeRangePicker from './cells/TimeRangePicker';
import Tab from './Tab';
import Text from './Text';

function Wells(props) {
  const { items, loading, handleEdit, displayAll } = props;
  const dataSource = filterPrepareAndSortWells(items, displayAll).map(mergeMasterStagingData);
  const timeOfNextChange = calculateTimeOfNextChange(dataSource);

  if(timeOfNextChange && dataSource) {
    timeOfNextChange.forEach(well => {
      const source = dataSource.find(x => x.id === well.wellId);

      if(source) {
        source.timeOfNextChange = well.nextChange;
      }
    })
  }

  const columns = [
    {
      title: 'Well',
      width: '130px',
      dataIndex: 'name',
      render: (text, well) => {
        const keepFlowingIcon = well.keepFlowing && (
          <Tooltip title='Keep flowing'>
            <Images.KeepFlowing className='keep-flowing-symbol'  />
          </Tooltip>
        );
        return (
          <div className="well-name-container">
            <Tag color={getWellColor(well)} key={`name-${well.id}`}>
              {text}
            </Tag>
            {keepFlowingIcon}
          </div>
        );
      }
    },
    {
      title: 'Not available',
      dataIndex: 'notAvailable',
      width: '85px',
      textWrap: 'word-break',
      render: (checked, asset) => (
        <CheckboxField
          dataIndex='notAvailable'
          checked={checked}
          handleEdit={handleEdit}
          asset={asset}
        />
      )
    },
    {
      title: renderUnit('Proposed', Sm3s),
      dataIndex: 'proposedRate',
      width: '90px',
      render: (text, well) => {
        const { currentRate, proposedRate } = well;
        const faded = proposedRate == null || (currentRate === 0 && proposedRate === 0) ? ' low-opacity' : '';
        return (
          <span className={`value-wrap${faded}`}>
            {proposedRate != null && proposedRate.toFixed(2)}
          </span>
        );
      }
    },
    {
      title: renderUnit('Current', Sm3s),
      dataIndex: 'currentRate',
      width: '90px',
      render: (text, well) => {
        const { currentRate, proposedRate } = well;
        const diff = Math.abs(currentRate - proposedRate);
        if (Number.isNaN(diff) && (Number.isNaN(text) || text == null)) {
          return (
            <span className='value-wrap low-opacity'>
              {' '}
            </span>
          );
        }
        const validDiff = diff <= proposedRate * wellRatesTreshold();
        const faded = currentRate === 0 && proposedRate === 0 ? ' low-opacity' : '';
        return (
          <span className={`value-wrap ${validDiff ? 'green-value' : 'red-value'}${faded}`}>
            {currentRate.toFixed(2)}
          </span>
        );
      }
    },
    {
      title: 'Time of next change',
      dataIndex: 'timeOfNextChange',
      textWrap: 'word-break',
      width: '115px',
      render: (change) => {
        if(!change) {
          return nextChange('');
        }
        return nextChange(change.time, change.increase || -1 * change.decrease);
      }
    },
    {
      title: 'Instructions',
      dataIndex: 'instructions',
      width: '25%',
      render: (text, asset) => <Text key={asset.id}>{text}</Text>
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      editable: true,
      width: '25%',
      render: (text, asset) => <Text key={asset.id}>{text}</Text>
    },
    {
      title: 'Outage',
      dataIndex: 'outageStartTime',
      render: (time, asset) => <TimeRangePicker asset={asset} handleEdit={handleEdit} />
    }
  ];

  const tableColumns = columns.map(col => tableColumn(col, handleEdit));
  return (
    <Table
      components={EditableCell.components}
      rowClassName={() => 'editable-row'}
      rowKey={record => record.id}
      pagination={false}
      loading={loading}
      dataSource={dataSource}
      columns={tableColumns}
      scroll={{y: 680}}
    />
  );
}

Wells.Tab = (props) => <Tab {...props} name='Wells' />;

export default Wells;

