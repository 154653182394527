import React, {Component} from 'react';
import PropTypes from "prop-types";
import { track } from '../mixpanel';

class WellsFilterToggle extends Component {
  onClick = (e) => {
    let unselect;
    
    if(e.currentTarget.id === 'openBtn') {
      unselect = document.getElementById('allBtn');
      track("open_or_all", {"new_state": false});
      this.props.handleFilterSelectionToggle(false);
    } else {
      unselect = document.getElementById('openBtn');
      track("open_or_all", {"new_state": true});
      this.props.handleFilterSelectionToggle(true);
    }
    
    unselect.classList.remove('selected')
    e.currentTarget.classList.add('selected')
  };
  
  render() {
    return (
      <div className="toggle-switch">
        <button type="button" className="selected" id="openBtn" onClick={this.onClick}>OPEN</button>
        <button type="button" id="allBtn" onClick={this.onClick}>ALL</button>
      </div>
    );
  }
}

WellsFilterToggle.propTypes = {
  handleFilterSelectionToggle: PropTypes.func.isRequired
}

export default WellsFilterToggle;

