import { getRateFromTimestamp } from './rates';

export default function filterPrepareAndSortWells(wells, displayAll = true) {
  const now =  Date.now();
  const filtered = !displayAll
    ? [...wells].filter((well) => {
      return well.master.wellStatus === 'OPEN' && well.master.wellType !== 'WI';
    })
    : [...wells];

  const calculated = filtered.map(well => {
      const { master, ...rest } = well;
      const proposedRate = getRateFromTimestamp(master.wellRates, now);

      if (!Number.isNaN(proposedRate)) {
        master.proposedRate = proposedRate;
      }
      return { ...rest, master };
    }
  );

  return calculated.sort((a, b) => {
    if (a.master.wellStatus !== b.master.wellStatus) {
      return a.master.wellStatus === 'OPEN' ? -1 : 1;
    }
    if (a.master.keepFlowing !== b.master.keepFlowing) {
      return a.master.keepFlowing ? -1 : 1;
    }
    if (a.master.notAvailable !== b.master.notAvailable) {
      return a.master.notAvailable ? 1 : -1;
    }
    const cgrA = a.master.cgr || 0;
    const cgrB = b.master.cgr || 0;
    if (cgrA !== cgrB) {
      return cgrB - cgrA;
    }
    return a.master.name.localeCompare(b.master.name);
  });
}
