import PropTypes from 'prop-types';

export const defaultProps = {
  children: null,
  title: null,
  isArticle: false,
  minNumber : () => 0.0,
  maxNumber : () => undefined
};

export const propTypes = {
  children: PropTypes.node,
  record: PropTypes.shape({}).isRequired,
  dataIndex: PropTypes.string.isRequired,
  title: PropTypes.node,
  isArticle: PropTypes.bool,
  minNumber : PropTypes.func,
  maxNumber : PropTypes.func
};