import { fetchOutage, fetchSequences, filterSequences, filterTimeseries, getDataFieldsValues } from './tools';
import { extractOptimalWellRates} from '../func/rates';
import { endOfNzDay, startOfNzDay, nzTimeFromTimestamp } from '../time';
import { regExSuffix } from '../func';

async function fetchHistoricalFlowRates(client, ts) {
  if (!ts) return [];
  const [{ datapoints }] = await client.datapoints.retrieve({
    items: [
      {
        id: ts.id,
        start: startOfNzDay().valueOf(),
        end: endOfNzDay().valueOf(),
        granularity: '30m',
        aggregates: ['average']
      }
    ]
  });
  return datapoints;
}


export default async function loadWellData(client, wellAsset, timeSeries, optimalWellRates) {
  const wellStatus = wellAsset.metadata.ACTIVE_WELL_STATUS;
  const wellType = wellAsset.metadata.WELL_TYPE;

  const currentRateFilter = ts => {
    const metadata = ts.metadata || {};
    return metadata.piafatt === 'FLOW';
  };
  const targetRateFilter = ts => ts.name.match(regExSuffix('_WELL_RATE'));
  const chokeFilter = ts => {
    const metadata = ts.metadata || {};
    return metadata.piafatt === 'CHOKE_POSITION';
  };
  const notAvailableFilter = ts => ts.name.match(regExSuffix('NOT_AVAILABLE'));
  const cgrFilter = ts => ts.name.match(regExSuffix('_WELL_CGR'));
  const wgrFilter = ts => ts.name.match(regExSuffix('_WELL_WGR'));
  const minFlowRateFilter = ts => ts.name.match(regExSuffix('_WELL_RATE_MIN'));
  const maxLTRateFilter = ts => ts.name.match(regExSuffix('_WELL_RATE_MAX'));
  const maxSTRateFilter = ts => ts.name.match(regExSuffix('_WELL_RATE_MAX_ST'));
  const STRateAllowedFilter = ts => ts.name.match(regExSuffix('ST_RATE_ALLOWED'));
  const keepFlowingFilter = ts => ts.name.match(regExSuffix('KEEP_FLOWING'));
  const instructionsFilter = seq => seq.name.match(regExSuffix('INSTRUCTIONS'));
  const commentFilter = seq => seq.name.match(regExSuffix('COMMENTS'));
  const historicalFlowRatesFilter = currentRateFilter;

  const [
    currentRateTs,
    targetRateTs,
    chokeTs,
    notAvailableTs,
    cgrTs,
    wgrTs,
    minFlowRateTs,
    maxLTRateTs,
    maxSTRateTs,
    STRateAllowedTs,
    keepFlowingTs,
    historicalFlowRatesTs
  ] = filterTimeseries(timeSeries, [
    currentRateFilter,
    targetRateFilter,
    chokeFilter,
    notAvailableFilter,
    cgrFilter,
    wgrFilter,
    minFlowRateFilter,
    maxLTRateFilter,
    maxSTRateFilter,
    STRateAllowedFilter,
    keepFlowingFilter,
    historicalFlowRatesFilter
  ]);

  const sequences = await fetchSequences(client, wellAsset.id);
  const [instructionsSeq, commentSeq] = filterSequences(sequences, [
    instructionsFilter,
    commentFilter
  ]);

  const outageEvent = await fetchOutage(client, wellAsset.id);

  const millicubesPerHour = 277.78;

  const dataFields = {
    currentRate: {
      ts: currentRateTs
    },
    targetRate: {
      ts: targetRateTs
    },
    choke: {
      ts: chokeTs
    },
    notAvailable: {
      ts: notAvailableTs
    },
    cgr: {
      ts: cgrTs
    },
    wgr: {
      ts: wgrTs
    },
    minFlowRate: {
      ts: minFlowRateTs
    },
    maxLTRate: {
      ts: maxLTRateTs
    },
    maxSTRate: {
      ts: maxSTRateTs
    },
    STRateAllowed: {
      ts: STRateAllowedTs
    },
    keepFlowing: {
      ts: keepFlowingTs
    },
    instructions: {
      sequence: instructionsSeq,
      columnExternalId: 'instruction'
    },
    comment: {
      sequence: commentSeq,
      columnExternalId: 'comment'
    },
    outage: {
      event: outageEvent,
      subtype: 'well outage'
    },
    historicalFlowRates: {
      ts: historicalFlowRatesTs
    }
  };

  const [values, historicalFlowRates] = await Promise.all([
    getDataFieldsValues(client, dataFields),
    fetchHistoricalFlowRates(client, historicalFlowRatesTs)
  ]);

  const wellRates = extractOptimalWellRates(
    optimalWellRates,
    wellAsset.externalId
  );

  const condensateRate = (values.currentRate * values.cgr) / millicubesPerHour;
  const waterRate = (values.currentRate * values.wgr) / millicubesPerHour;
  return {
    id: wellAsset.id,
    master: {
      externalId: wellAsset.externalId,
      wellStatus,
      wellType,
      name: wellAsset.name.match(/M[AB]+-\d{1,2}$/)[0],
      currentRate: values.currentRate,
      targetRate: values.targetRate,
      choke: values.choke,
      cgr: values.cgr,
      condensateRate,
      wgr: values.wgr,
      waterRate,
      minFlowRate: values.minFlowRate,
      maxLTRate: values.maxLTRate,
      maxSTRate: values.maxSTRate,
      STRateAllowed: values.STRateAllowed === 1,
      keepFlowing: values.keepFlowing === 1,
      notAvailable: values.notAvailable === 1,
      instructions: values.instructions[0],
      comment: values.comment[0],
      outageStartTime: outageEvent
        ? nzTimeFromTimestamp(outageEvent.startTime)
        : null,
      outageEndTime: outageEvent
        ? nzTimeFromTimestamp(outageEvent.endTime)
        : null,
      historicalFlowRates,
      wellRates
    },
    staging: {},
    dataFields
  };
}
