import post from './post';
import configuration from '../configuration';
import { testConfig } from '../func';

const postSingleField = async (client, field) => {
  const config = configuration();
  testConfig(config);
  const path = config.restPath;
  const messageBody = {
    purpose: 'save',
    fields:[ field ],
    config
  };
  return post(path, client, JSON.parse(JSON.stringify(messageBody)));
};
export default postSingleField;
