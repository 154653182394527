
const getMessageFrom200status = ({status, message, warning}) => {
  if (status !== 200) {
    const error = new Error();
    error.data = { message, status };
    throw error;
  }
  return {message, warning};
};

export default getMessageFrom200status;