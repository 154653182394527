import { TimePicker } from 'antd';
import React from 'react';
import { endOfNzDay, nzTimeFromTimestamp, startOfNzDay } from '../../time';


const disabledHours = (from) => {
  const hours = [];
  for(let i = 0; i < from.hour(); i++){
    hours.push(i);
  }
  return hours;
};
const disabledMinutes = (selectedHour, from) => {
  if (selectedHour === from.hour()){
    if(from.minute() > 30) return [0];
  }
  return [];
};
const outageStartTime = (asset) => {
  const outageStart = nzTimeFromTimestamp(asset.outageStartTime.valueOf());
  outageStart.add(30, 'min');
  return outageStart;
};
const change = (id, dataIndex, handleEdit, startTime, value) => {
  if (value === null) {
    handleEdit({ id, dataIndex, value });
    return;
  }
  const timezoneDate = startTime;
  timezoneDate.hours(value.hours());
  timezoneDate.minutes(value.minutes());
  timezoneDate
    .seconds(0)
    .milliseconds(0);
  handleEdit({ id, dataIndex, value: timezoneDate });
};
const commonTimePickerProps = (asset, index, handleEdit, startTime) => {
  const classNames = index;
  return ({
    className: classNames,
    defaultOpenValue: startTime,
    format: 'HH:mm',
    minuteStep: 30,
    disabledHours: () => disabledHours(startTime),
    disabledMinutes: (selectedHour) => disabledMinutes(selectedHour, startTime),
    onChange: value => change(asset.id, index, handleEdit, startTime, value)
  });
};

const EndTimePicker = ({asset, handleEdit}) => {
  if(!asset.outageStartTime) {
    return null;
  }
  const index = 'outageEndTime';
  const startTime = outageStartTime(asset);
  const eod = endOfNzDay();
  const outageEndTime = asset[index];
  return (
    <>
      <span className='time-picker-spacing'> - </span>
      {
        !outageEndTime || outageEndTime.valueOf() === eod.valueOf()
          ? (
            <TimePicker
              {...commonTimePickerProps(asset, index, handleEdit, startTime)}
              placeholder='24:00'
              value={null}
            />
          )
          : (
            <TimePicker
              {...commonTimePickerProps(asset, index, handleEdit, startTime)}
              value={startTime > asset[index] ? startTime : asset[index]}
            />
          )
      }
    </>
  )
};
const isModifiedAsset = (asset, dataIndex) => !!asset.edited && asset.edited.includes(dataIndex);

const TimeRangePicker = ({ handleEdit, asset}) =>
  {
    const index = 'outageStartTime';
    const startTime = startOfNzDay();
    const isModified = isModifiedAsset(asset, index);
    const classNames = `modifiableField ${isModified ? ' modified' : ''}`;
    return (
      <div className={classNames}>
        <TimePicker
          {...commonTimePickerProps(asset, index, handleEdit, startTime)}
          placeholder='Select time'
          value={asset[index]}
        />
        <EndTimePicker asset={asset} handleEdit={handleEdit} />
      </div>
    );
  };

export default TimeRangePicker;
