import React from 'react';
import { Alert, Icon, Steps } from 'antd';
import { arrayOf, number, string } from 'prop-types';

const { Step } = Steps;
const renderTitle = (currentStep, step) => {
  if (step === currentStep) return 'In Progress';
  if (step < currentStep) return 'Finished';
  return 'Waiting';
};

function StatusDescription({ stepDescriptions, currentStep, status, message, warning }) {
  const steps = stepDescriptions.map( (desc, step) => (
    <Step key={`step-${step+0}`} title={renderTitle(currentStep, step)} description={desc} />
  ));
  return (
    <>
      <Steps current={currentStep} status={status}>
        {steps}
        <Step title='Done' icon={<Icon type='smile-o' />} />
      </Steps>
      {message && (
        <Alert
          message={message}
          type={status}
        />
      )}
      {warning && (
        <Alert
          className='run-status-additional'
          message={warning}
          type='warning'
        />
      )}
    </>
  );
}
StatusDescription.propTypes = {
  stepDescriptions: arrayOf(string).isRequired,
  currentStep: number.isRequired,
  status: string || undefined,
  message: string || undefined,
  warning: string || undefined
};
StatusDescription.defaultProps = {
  status: undefined,
  message: undefined,
  warning: undefined
};
export default StatusDescription;
