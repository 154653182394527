import React from 'react';
import {Input, Tooltip} from 'antd';
import { defaultProps, propTypes } from './cells/unitValueProps';
import Images from '../Images';
import { forecastTreshold } from '../func';

const diffArrowValue = (difference, diffTip) => {
  let diff = null;
  if (difference && difference !== 0) {
    let img = null;
    let color = '';

    if (difference > forecastTreshold()) {
      img = <Images.ArrowUpRed />;
      color = 'positive-red';
    } else if (difference > 0) {
      img = <Images.ArrowUpGreen />;
      color = 'positive-green';
    } else if (difference < -forecastTreshold()) {
      img = <Images.ArrowDownRed />;
      color = 'negative-red';
    } else if (difference < 0) {
      img = <Images.ArrowDownGreen />;
      color = 'negative-green';
    }

    if (img) {
      diff = diffTip
        ? (
          <Tooltip title={diffTip}>
            {img}
            <p className={color}>{Number.parseFloat(difference).toFixed(1)}</p>
          </Tooltip>
        )
        : (
          <>
            {img}
            <p className={color}>{Number.parseFloat(difference).toFixed(1)}</p>
          </>
        );
    } else {
      diff = null;
    }
  }
  return diff;
};

function HighlightedUnitValue(props) {
  const { description, value, unit, disabled, difference, diffTip, events, editing, className } = props;

  const diff = !editing && diffArrowValue(difference, diffTip);
  const classNames = `HighlightedUnitValue ${className || '' }`;
  return(
    <div className={classNames}>
      <p>{`${description} [${unit}]`}</p>
      <div className={diff ? 'indicator-container' : ''}>
        <Input
          value={value}
          disabled={!events.onClick || disabled}
          {...events}
        />
        {diff}
      </div>
    </div>
  );

}

HighlightedUnitValue.defaultProps = defaultProps;
HighlightedUnitValue.propTypes = propTypes;
export default HighlightedUnitValue;
