import React from 'react';
import { minutesSinceMidnight, nowLocalTime, startOfNzDay } from '../../time';
import { getHistoricalFlowRate, extractRateWithinTimestamp } from '../../func/rates';

function roundNumber (number, indicator=null) {

  let indicatorIcon = null;
  const faded = Number.isNaN(number) || !number ? ' low-opacity' : '';
  if(indicator === 'increase') {
    indicatorIcon = <img className="smaller-arrow" src="/svgs/arrow-up-green.svg" alt="Arrow up" />
  }
  else if(indicator === 'decrease') {
    indicatorIcon = <img className="smaller-arrow" src="/svgs/arrow-down-red.svg" alt="Arrow down" />
  }

  return(
    <span className={`value-wrap${faded}`}>
      {number != null && !Number.isNaN(number) && number.toFixed(2)}
      {indicatorIcon || null}
    </span>
  );
}

const horizontalView = (dataSource) => {
  const columns = [];
    // add historical columns
    const halfHoursSinceMidnight = Math.floor(minutesSinceMidnight() / 30);
    for (let i = 0; i < halfHoursSinceMidnight; i += 1) {
      const startOfHalfHour = startOfNzDay()
        .clone()
        .add(i * 30, 'minutes');
      columns.push({
        title: startOfHalfHour.format('HH:mm'),
        width: 2,
        render: ({ historicalFlowRates }) => {
          const historicalFlowRate = getHistoricalFlowRate(
            historicalFlowRates,
            startOfHalfHour
          );
          if (historicalFlowRate) {
            return roundNumber(historicalFlowRate.average);
          }
          return null;
        }
      });
    }

    // add current/future columns
    const halfHoursBeforeNextMidnight = 24 * 2 - halfHoursSinceMidnight;
    for (let i = 0; i < halfHoursBeforeNextMidnight; i += 1) {
      const mmt = startOfNzDay()
        .clone()
        .add(30 * halfHoursSinceMidnight + i * 30, 'minutes');
      const columnOptions = {
        title: mmt.format('HH:mm'),
        width: 2,
        render: ({ wellRates }) => {
          const rate = extractRateWithinTimestamp(wellRates, mmt.valueOf());
          return roundNumber(rate);
        }
      };
      const mmtPlusHalfHour = mmt.clone().add(30, 'minutes');
      const now = nowLocalTime();
      if (mmt <= now && now < mmtPlusHalfHour) {
        columnOptions.className = 'green-background';
      }
      columns.push(columnOptions);
    }

    return [columns, dataSource];
  };

export default horizontalView;