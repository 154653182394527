import React, { Component } from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import WellsFilterToggle from "./WellsFilterToggle";
import { track } from '../mixpanel';

const { TabPane } = Tabs;

class TabView extends Component {
  constructor(props) {
    super(props);
    const lastTab = localStorage.getItem('lastTab');
    const activeTab = lastTab || `tab-0`;
    track(activeTab);
    this.state = {
      currentActiveTab: activeTab
    };
  }
  
  render() {
    const { tabularContent, handleFilterSelectionToggle, configButton } = this.props;
    const filterToggle = <WellsFilterToggle handleFilterSelectionToggle={handleFilterSelectionToggle} />;
    const tabs = tabularContent.map((props, i) => {
      const key = `tab-${i}`;
      return (
        <TabPane key={key} {...props} />
      );
    });
    const onChange = (activeKey) => {
      localStorage.setItem('lastTab', activeKey);
      track(activeKey);
      this.setState({
        currentActiveTab: activeKey
      })
    };
    return (
      <div>
        <div className='toolbar-group'>
          {this.state.currentActiveTab === 'tab-0' ? null : filterToggle}
          {configButton}
        </div>
        <Tabs
          className="Tabs"
          defaultActiveKey={this.state.currentActiveTab}
          size="large"
          animated={false}
          tabBarGutter={16}
          onChange={onChange}
        >
          {tabs}
        </Tabs>
      </div>
    );
  }
}

TabView.propTypes = {
  tabularContent: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterSelectionToggle: PropTypes.func.isRequired,
  configButton: PropTypes.node.isRequired
};

export default TabView;