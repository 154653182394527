import { endOfNzDay } from '../../time';

const getMissingOutageEndTime = (outageStartTime, outageEndTime) => {
  if(outageEndTime && outageStartTime) {
    return {outageEndTime};
  }
  if(!outageStartTime) {
    return {outageEndTime: null};
  }
  return {outageEndTime: endOfNzDay()};
};
export default getMissingOutageEndTime;