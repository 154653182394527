import React from 'react';
import { Tag } from 'antd';
import { getWellColor } from '../../func';

export const WellTitle = (props) => <span role="button" {...props}>Well</span>;

export const renderName = (diValue, asset) => {
  if (diValue === asset.timestamp) {
    return <span className="timestamp">{diValue}</span>
  }
  return (
    <span>
      <Tag color={getWellColor(asset)} key={asset.id}>
        {diValue}
      </Tag>
    </span>
  );
};