import moment from 'moment-timezone';

export const minutesInADay = 1440;
const timezone = 'Etc/GMT+12';

export const nowLocalTime = () => moment();
export const nowNzTime = () => moment.tz(timezone);

export const startOfNzDay = () =>  nowNzTime().startOf('day');
export const endOfNzDay = () =>
  nowNzTime().endOf('day').add(1,'ms');

export const diffMin = (a, b) => a.diff( b,'minutes');

export const minutesSinceMidnight = () => diffMin(nowNzTime(), startOfNzDay());

export const nzTimeFromTimestamp = (timestamp) => moment.tz(timestamp, timezone);

