import { isLoginPopupWindow, loginPopupHandler } from '@cognite/sdk';
import { Alert, message as antdMessage, notification, Spin, Tag } from 'antd';
import React from 'react';
import './App.css';
import {
  AppHeader,
  ConfigButton,
  handleChangedMasterValues,
  renderFailedSaveProgress,
  renderFinishedSaveProgress,
  renderSaveProgress,
  TabView,
} from './components';
import configuration, { initConfiguration, logIn} from './configuration';
import { loadData, refreshDataFromCDF } from './fetch';
import {
  aggregates as makeAggregates,
  canBePostedWithoutCalculation,
  diffMasterValues,
  getMessageFrom200status,
  hasAnyModifiedValues,
  modifyRecords,
  parseManualResult,
  testConfig,
} from './func';
import getIdsForDataField from './func/getIdsForDataField';
import mainContent from './mainContent';
import { postManualRun, postSaveAndRun, postSingleField } from './post';
import './styles/css/index.css';
import { track, setup_mixpanel } from './mixpanel';
import { appConfig } from './configuration';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wells: {
        items: [],
        loading: true,
        displayAll: false,
      },
      trains: {
        items: [],
        loading: true,
      },
      fetching: false,
      saving: false,
      halted: false,
      configModal: false,
    };
    window.globalState = {
      editing: false,
    };
  }

  componentDidMount() {
    if (isLoginPopupWindow()) {
      loginPopupHandler();
      return;
    }

    const { client } = this.state;
    if (!client) {

      logIn().then((login_client) => {
        this.setState({ client: login_client })
        const config = configuration();
        setup_mixpanel(client, appConfig);
        if (!config) {
          this.setFetching(true);
          antdMessage.info('Fetching config...');
          initConfiguration(login_client).then(() => {
            this.setFetching(false);
            this.componentDidMount();
            return this.reset();
          });
          return;
        }
        const { refreshIntervalInSeconds } = config;
        const refreshIntervalInMs = refreshIntervalInSeconds * 1000;
        setInterval(() => {
          track('activity');

          if (
            window.globalState.editing ||
            this.state.halted ||
            this.isInProgress() ||
            this.isInManualMode() ||
            this.hasModifiedValues()
          )
            return;
          antdMessage.info('Automatically refreshing...', 2);
          this.refresh().then(() => antdMessage.info('Data refreshed', 2));
        }, refreshIntervalInMs);
      })
      this.setState({ client: logIn() }, this.componentDidMount);
      return;
    }
  }
  isInProgress = () => this.state.fetching || this.state.saving;

  /** Has */
  hasModifiedValues = () => {
    return (
      hasAnyModifiedValues(this.state.wells.items) ||
      hasAnyModifiedValues(this.state.trains.items)
    );
  };

  /** Handles */
  handleEdit = (context, id, dataIndex, value, constraintFunc) => {
    const { client } = this.state;
    if (!client) {
      // TODO: warn user
      return;
    }
    const { items, modifiedAssetNumber } = modifyRecords(
      this.state[context].items,
      id,
      dataIndex,
      value,
      constraintFunc
    );
    const updateCollectionItems = () =>
      this.setState((previousState) => {
        const newState = {
          [context]: {
            ...previousState[context],
            items,
          },
        };
        return newState;
      });

    if (canBePostedWithoutCalculation(dataIndex)) {
      const modifiedAsset = items[modifiedAssetNumber];
      const ids = getIdsForDataField(modifiedAsset, dataIndex);
      if (ids.externalId) {
        postSingleField(client, {
          ...ids,
          dataIndex,
          value,
        })
          .then((callback) => {
            if (callback.data) {
              const { save_values: saveValues } = callback.data;
              if (saveValues && saveValues.status === 200) {
                const { staging, master, edited, ...asset } = modifiedAsset;
                const { [dataIndex]: valueToMaster, ...restStaging } = staging;
                items[modifiedAssetNumber] = {
                  ...asset,
                  master: {
                    ...master,
                    [dataIndex]: valueToMaster,
                  },
                  staging: { ...restStaging },
                };
                antdMessage.success(saveValues.message);
              } else {
                callback.reject({
                  reason: saveValues,
                  error: 'Multi response - Failed to save',
                });
              }
            } else
              callback.reject({
                reason: callback,
                error: 'Unknown - callback in reason',
              });
          })
          .catch((err) => {
            antdMessage.error(
              `Failed to save ${dataIndex} to ${context} for unknown reason`
            );
            console.error(err.message);
          })
          .finally(updateCollectionItems);
      }
    } else {
      updateCollectionItems();
    }
  };

  /** Setters */

  setWells = (wells, loading) => {
    this.setState((prevState) => ({
      wells: {
        ...prevState.wells,
        loading,
        items: wells,
      },
    }));
  };

  setTrains = (trains, loading) => {
    this.setState({
      trains: {
        loading,
        items: trains,
      },
    });
  };

  setAggregates = (aggregates) => {
    this.setState({
      aggregates,
    });
  };

  setFetching = (fetching) => {
    const alreadyFetching = this.state.fetching;
    if (alreadyFetching === fetching) {
      return;
    }
    this.setState((prev) => ({
      fetching,
      wells: {
        ...prev.wells,
        loading: fetching,
      },
      trains: {
        ...prev.trains,
        loading: fetching,
      },
    }));
  };

  handleFilterSelectionToggle = (bool) =>
    this.setState((prevState) => ({
      wells: {
        ...prevState.wells,
        displayAll: bool,
      },
    }));

  setConfigVisibility = (show) =>
    this.setState({
      halted: show,
      configModal: show,
    });

  /** Other */
  reset = async () => {
    if (this.state.halted) {
      return;
    }
    this.setFetching(true);
    const { client, wells, trains } = this.state;
    this.setWells(wells.items, true);
    this.setTrains(trains.items, true);
    const {
      wells: newWells,
      trains: newTrains,
      aggregates: newAggregates,
    } = await loadData(client, (e) => this.handleFetchingError(e));
    this.setWells(newWells, false);
    this.setTrains(newTrains, false);
    this.setAggregates(newAggregates);
    this.setFetching(false);
  };

  refresh = async () => {
    if (this.state.halted) {
      return;
    }
    this.setFetching(true);
    const { client, wells, trains, aggregates } = this.state;
    const { newWells, newTrains, newAggregates } = await refreshDataFromCDF(
      client,
      wells,
      trains,
      aggregates,
      (e) => this.handleFetchingError(e)
    );
    this.setWells(newWells, false);
    this.setTrains(newTrains, false);
    this.setAggregates(newAggregates);
    this.setFetching(false);
  };

  /** Saves */
  save = async () => {
    const config = configuration();
    testConfig(config);
    this.setState({ saving: true });
    await this.postToOrchestratorAndRefresh();
    this.setState({ saving: false });
  };

  postToOrchestratorAndRefresh = async () => {
    const { client } = this.state;
    const inManualMode = this.isInManualMode();
    const modifiedValues = this.hasModifiedValues();
    const heading = inManualMode
      ? 'Test run'
      : `${modifiedValues ? 'Save and run' : 'Update and run'}`;
    const progressId = Date.now();
    let step = 0;
    const stepDescriptions = [
      'Fetching latest from CDF',
      'Sending values for calculation',
      !inManualMode ? 'Fetching updated values' : 'Refreshing values',
    ].filter((v) => !!v);

    try {
      const { wells, trains, aggregates } = this.state;
      renderSaveProgress(
        progressId,
        { stepDescriptions, currentStep: step++ },
        heading
      );
      this.setFetching(true);
      const latest = await refreshDataFromCDF(
        client,
        wells,
        trains,
        aggregates,
        (e) => this.handleFetchingError(e)
      );
      // latest is merged state and contains what would be used on save and run

      const diff = diffMasterValues({ wells, trains, aggregates }, latest);
      const { changes } = diff;
      if (changes) {
        track('configuration_master_save', { new_state: JSON.stringify(diff) });
        handleChangedMasterValues(diff);
      }
      this.setWells(latest.newWells, false);
      this.setTrains(latest.newTrains, false);
      this.setAggregates(latest.newAggregates);
      this.setFetching(false);
    } catch (e) {
      renderFailedSaveProgress(
        progressId,
        { stepDescriptions, currentStep: step - 1 },
        'Problems fetching'
      );
      return;
    }

    renderSaveProgress(
      progressId,
      { stepDescriptions, currentStep: step++ },
      heading
    );
    try {
      if (!inManualMode) {
        const { wells, trains, aggregates } = this.state;
        const response = await postSaveAndRun(client, {
          wells,
          trains,
          aggregates,
        });

        renderSaveProgress(
          progressId,
          { stepDescriptions, currentStep: step++ },
          heading
        );
        const runOpt = response.data && response.data.run_optimization;
        const { message, warning } = runOpt && getMessageFrom200status(runOpt);
        renderSaveProgress(
          progressId,
          { stepDescriptions, message, currentStep: step++, warning },
          heading
        );
        await this.reset();

        renderFinishedSaveProgress(
          progressId,
          { stepDescriptions, message, currentStep: step++, warning },
          heading
        );
      } else {
        const { wells, trains, aggregates } = this.state;
        const response = await postManualRun(client, {
          wells,
          trains,
          aggregates,
        });
        const manual = response.data && response.data.manual;
        const { message, warning } = getMessageFrom200status(manual);
        const manualWells = parseManualResult(this.state.wells, manual.json);
        this.setWells(manualWells, false);

        renderSaveProgress(
          progressId,
          { stepDescriptions, message, currentStep: step++, warning },
          heading
        );
        this.setAggregates(makeAggregates(aggregates, manualWells));
        renderFinishedSaveProgress(
          progressId,
          { stepDescriptions, message, currentStep: step++, warning },
          heading
        );
      }
    } catch (e) {
      const body = e.data ? ` -  ${e.data.message}` : e.stack;
      const message = `${e.name}${body}`;
      antdMessage.error(<p>{e.message || e.name}</p>);
      renderFailedSaveProgress(
        progressId,
        { stepDescriptions, message, currentStep: step - 1 },
        heading
      );
    }
  };

  isInManualMode = () => {
    const { aggregates } = this.state;
    if (!aggregates) return false;
    const { staging } = aggregates;
    return !!staging && !!staging.scheduledQuantity;
  };

  /** Renders */
  aggregatesConfig = () => {
    const { aggregates } = this.state;
    const manualQt = {
      isModified: this.isInManualMode,
      reset: () => this.setState(makeAggregates.reset),
    };
    const events = {};
    events.onChange = !aggregates
      ? undefined
      : (event) => {
        const { value } = event.target;
        const { staging } = this.state.aggregates;
        if (staging.scheduledQuantity === value) return;
        this.setState((prevState) => ({
          aggregates: {
            ...(prevState.aggregates || {}),
            staging: {
              scheduledQuantity: value,
            },
          },
        }));
      };
    events.onClick = !events.onChange
      ? undefined
      : () => {
        window.globalState.editing = true;
        const { master } = aggregates;
        if (!manualQt.isModified()) {
          const asNumber = Number.parseFloat(master.scheduledQuantity);
          events.onChange({ target: { value: asNumber.toFixed(1) } });
        }
      };
    events.onBlur = !events.onChange
      ? undefined
      : (event) => {
        window.globalState.editing = false;
        const { value } = event.target;
        if (!value) {
          manualQt.reset();
        } else {
          const asNumber = Number.parseFloat(value);
          if (!asNumber.isNaN) {
            events.onChange({ target: { value: asNumber.toFixed(1) } });
          } else {
            manualQt.reset();
          }
        }
      };
    events.onPressEnter = (e) => e.target.blur();
    if (events.onChange) {
      manualQt.events = events;
    }
    return { aggregates, manualQt };
  };

  handleFetchingError(e) {
    if (e.data) {
      this.setState({ halted: true });
      const args = {
        message: e.message,
        description: e.data.message,
        duration: 0,
        placement: 'topLeft',
        onClose: () => {
          this.setState({ halted: false });
        },
      };
      notification.warning(args);
    } else {
      antdMessage.error(e.message);
    }
  }

  render() {
    const { client, configModal, trains, wells } = this.state;
    const config = configuration();
    if (!client || !config) {
      return (
        <div className="App">
          <Spin tip="Loading...">
            <Alert
              message="Application is loading"
              description="Application is waiting for required resources to be applied"
              type="info"
            />
          </Spin>
        </div>
      );
    }
    const configButton = (
      <ConfigButton
        showModalFunc={this.setConfigVisibility}
        visibility={configModal}
        client={client}
      />
    );

    const handleWellsEdit = ({ id, dataIndex, value }, constraintFunc) => {
      return this.handleEdit('wells', id, dataIndex, value, constraintFunc);
    };

    const handleTrainsEdit = ({ id, dataIndex, value }, constraintFunc) => {
      return this.handleEdit('trains', id, dataIndex, value, constraintFunc);
    };

    return (
      <div className="App">
        <div className="cdf-project">
          {config.project !== 'omv' && (
            <Tag color="volcano">{config.project}</Tag>
          )}
        </div>
        <AppHeader
          inProgress={this.isInProgress}
          modifiedValues={this.hasModifiedValues()}
          aggregatesConfig={this.aggregatesConfig}
          saveAction={this.save}
          refreshAction={this.reset}
          beanUpProcedureURL={
            config.modelParameters?.frontEnd?.beanUpProcedureURL
          }
        />
        <main>
          <TabView
            tabularContent={mainContent({
              trains,
              wells,
              handleTrainsEdit,
              handleWellsEdit,
            })}
            handleFilterSelectionToggle={this.handleFilterSelectionToggle}
            configButton={configButton}
          />
        </main>
      </div>
    );
  }
}
export default App;