import { Switch } from 'antd';
import React from 'react';

const CheckboxField = (props) => {
  const {dataIndex, checked, handleEdit, asset} = props;
  const {id, edited} = asset;
  const isModified = edited.includes(dataIndex);
  const classNames = `modifiableField ${isModified ? ' modified' : ''}`;
  return (
    <div className={classNames}>
      <Switch
        key={`${dataIndex}-${id}`}
        checked={checked}
        onChange={value => handleEdit({ id, dataIndex, value })}
      />
    </div>
  )
};
export default CheckboxField;

