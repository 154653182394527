const availabilityColor = (asset) => {
  if(asset.notAvailable) {
    return 'gold'
  }
  return 'green';
};

export const  getWellColor = (well) => {
  if(well.wellStatus !== 'OPEN') {
    return 'red';
  }
  
  if(well.wellType === "WI") {
    return 'blue'
  }

  return availabilityColor(well);
};

export const  getTrainColor = (train) => {
  return availabilityColor(train);
};
