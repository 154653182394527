const reduceDataFieldValues = (dataFields => {
  const keptValues = [
    'id', 'externalId'
  ];
  const dataFieldNames = Object.keys(dataFields);
  const reducedDataFields = dataFieldNames.reduce((data, dataIndex) => {
    const dataField = dataFields[dataIndex];
    if (dataIndex === 'outage') {
      return {
        ...data,
        'outage': dataField
      };
    }
    const newDataField = {};
    Object.keys(dataField)
      .forEach(assetType => {
        const innerValue = dataField[assetType];
        if (!innerValue) {
          return;
        }
        Object.keys(innerValue)
          .forEach(fieldName => {
            if (fieldName === 'metadata') {
              const { type } = dataFields[dataIndex][assetType][fieldName];
              if (type) {
                newDataField.type = type;
              }
            } else if (keptValues.includes(fieldName)) {
              newDataField[fieldName] = dataFields[dataIndex][assetType][fieldName];

            }
          });
      });
    return {
      ...data,
      [dataIndex]: newDataField
    };
  }, {});
  return reducedDataFields;
});

export default reduceDataFieldValues;