
export default function extractRateWithinTimestamp(wellRates, timestamp) {
  let best = NaN;
  wellRates.forEach(({ rowNumber, value }) => {
    if (rowNumber <= timestamp) {
      best = value;
    }
  });
  return best || 0.0;
}
