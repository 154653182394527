import { diffMin, endOfNzDay, nowLocalTime } from '../../time';
import { extractRateWithinTimestamp } from '../rates';

function calculateWeightedSum(wells, startOfDay, minutesSinceMidnight) {
  const startOfFirstTimeWindow = startOfDay.add(
    Math.floor(minutesSinceMidnight / 30) * 30,
    'minutes'
  );

  let weightedSum = 0;
  const currentTimeslot = nowLocalTime();
  const endOfDay = endOfNzDay();
  const halfHoursPerDay = 48;

  while (currentTimeslot < endOfDay) {
    // eslint-disable-next-line no-restricted-syntax
    for (const well of wells) {
      const { wellRates, name } = well;
      const optimalWellRate = extractRateWithinTimestamp(wellRates, currentTimeslot);
      let value = optimalWellRate / halfHoursPerDay;
      if (name.startsWith('MB')) {
        value *= 0.95;
      }
      const timeSinceFirstTimeWindow = diffMin(currentTimeslot, startOfFirstTimeWindow);
      const isFirstTimeWindow = timeSinceFirstTimeWindow < 30;
      if (isFirstTimeWindow) {
        value *= 1 - timeSinceFirstTimeWindow / 30;
      }
      weightedSum += value;
    }
    currentTimeslot.add(30, 'minutes');
  }
  return weightedSum;
}

export default calculateWeightedSum;