import { Button, message } from 'antd';
import { CogniteClient } from '@cognite/sdk';
import React from 'react';
import { bool, shape, func } from 'prop-types';
import ConfigModal from './Modal';
import { postConfiguration } from '../../post';
import configuration from '../../configuration';
import { testConfig } from '../../func';
import Images from '../../Images';
import { track } from '../../mixpanel';

const reduceAndPostConfig = async (client, config, externalId) => {
  return postConfiguration(client, config, externalId);
};

const okFunc = async (client, changes) => {
  try {
    const config = configuration();
    testConfig(config);
    const externalId = config.modelParametersExternalId;
    const {modelParameters} = config;
    if (changes) {
      track("configuration_modal_ok", {new_state: JSON.stringify(changes)});
      const response = await reduceAndPostConfig(client, { ...modelParameters, ...changes }, externalId);
      message.success('New config submitted');
      const writeConfigStatus = response.data && response.data.write_config;
      let msg = '';
      if(writeConfigStatus) {
        msg = writeConfigStatus.message;
        if (writeConfigStatus.status !== 200) {
          const error = new Error();
          error.message = msg;
          throw error;
        }
        window.location.reload();
      }
    } else {
      message.info('no changes to post');
    }
  } catch (error) {
    message.error(`Problem saving config: ${error.message}`);
    console.error(error);
  }
};

const renderModal = (client, showModalFunc) => (
  <ConfigModal
    okFunc={(modelParametersChanges) =>
      okFunc(client, modelParametersChanges).finally(() => showModalFunc(false))}
    cancelFunc={() => showModalFunc(false)}
  />
);

const ConfigButton = ({client, visibility, showModalFunc}) => (
  <>
    <Button
      onClick={() => showModalFunc(true)}
      disabled={visibility}
      className='config-button'
    >
      <Images.Cogs />
      Configuration
    </Button>
    {visibility && renderModal(client, showModalFunc)}
  </>
);

ConfigButton.propTypes = {
  client: shape(CogniteClient).isRequired,
  visibility: bool.isRequired,
  showModalFunc: func.isRequired
};

export default ConfigButton;