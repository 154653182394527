import configuration from '../configuration';

const colors = () => {
  const {modelParameters} = configuration();
  const {frontEnd} = modelParameters;
  return frontEnd ? frontEnd.colour : {};
};

const forecast = () => {
  const {forecastThresholdTJ} = colors();
  return forecastThresholdTJ || 1;
};

const wellRates = () => {
  const {wellRateThresholdPercent} = colors();
  return wellRateThresholdPercent || .1;
};

export {forecast, wellRates};