import fetchFromCdf from './fetchFromCdf';
import loadTrainData from './trainData';
import loadAggregations from './aggregations';
import loadWellData from './wellData';

export default async function loadData(client, handleFetchingError) {
  const [wellAssets, trainAssets, optimalWellRates] = await fetchFromCdf(client);

  const trainsPromise = Promise.all(
    trainAssets.map(trainAsset => loadTrainData(client, trainAsset))
  );

  const timeSeries = await client.timeseries
    .list({ filter: { 
      assetIds: wellAssets.map(({ id }) => id),
      
    },
    limit: 1000}
    )
    .autoPagingToArray({ limit: Infinity });

  const wellsPromise = Promise.all(
    wellAssets.map(wellAsset =>
      loadWellData(
        client,
        wellAsset,
        timeSeries.filter(ts => ts.assetId === wellAsset.id),
        optimalWellRates
      )
    )
  );

  const [wells, trains] = await Promise.all([wellsPromise, trainsPromise]);
  try {
    const aggregates = await loadAggregations(client, wells);
    return {
      wells,
      trains,
      aggregates
    };
  } catch (e) {
    handleFetchingError(e);
    return {wells, trains, aggregates: undefined}
  }
}
