import React from 'react';
import UnitValue from '../cells/UnitValue';
import mergeMasterStagingData from '../../func/mergeMasterStagingData';
import { Sm3s, TJ, m3hr } from '../units';

const Aggregates = ({ aggregates, manualQt }) => {
  const {
    scheduledQuantity,
    dailyNomination,
    gnc,
    fuel,
    weldedPointImbalance,
    requiredRate,
    forecastProposed,
    forecastCurrentRate,
    currentRate,
    currentCondensate,
    optimizedCondensate,
  } = aggregates ? mergeMasterStagingData(aggregates) : {};
  const modifiedManualQt = manualQt.isModified();
  const description = modifiedManualQt
    ? 'Manual quantity'
    : 'Scheduled quantity';
  const classNames = `modifiableField ${modifiedManualQt ? ' modified' : ''}`;

  const leftAggregrate = (
    <div className="aggregatesRow left">
      <UnitValue
        description="Nomination"
        unit={TJ}
        value={dailyNomination}
        disabled
        headerDisplay
      />
      <UnitValue
        description="Fuel 1d avg"
        unit={TJ}
        value={fuel}
        disabled
        headerDisplay
      />
      <UnitValue
        description="GNC"
        unit={TJ}
        value={gnc}
        disabled
        headerDisplay
      />
      <UnitValue
        description="Imbalance"
        unit={TJ}
        value={weldedPointImbalance}
        disabled
        headerDisplay
      />
    </div>
  );

  const centerAggregate = (
    <div className="aggregatesRow center">
      <UnitValue
        className={classNames}
        description={description}
        unit={TJ}
        value={scheduledQuantity}
        highlighted
        headerDisplay
        editing={window.globalState.editing}
        {...manualQt}
      />
      <UnitValue
        description="Forecast current rate"
        unit={TJ}
        value={forecastCurrentRate}
        difference={forecastCurrentRate - scheduledQuantity}
        diffTip="Forecast daily difference if flowing at current rate"
        highlighted
        headerDisplay
      />
      <UnitValue
        description="Forecast proposed"
        unit={TJ}
        value={forecastProposed}
        difference={forecastProposed - scheduledQuantity}
        diffTip="Forecast daily difference if flowing at proposed rates"
        highlighted
        headerDisplay
      />
    </div>
  );

  const rightAggregate = (
    <div className="aggregatesRow right">
      <UnitValue
        description="Required rate"
        unit={Sm3s}
        value={requiredRate}
        disabled
        headerDisplay
      />
      <UnitValue
        description="Current rate"
        unit={Sm3s}
        value={currentRate}
        disabled
        headerDisplay
      />
      <UnitValue
        description="Current condensate"
        unit={m3hr}
        value={currentCondensate}
        disabled
        headerDisplay
      />
      <UnitValue
        description="Optimised condensate"
        unit={m3hr}
        value={optimizedCondensate >= 0 ? optimizedCondensate : ''}
        disabled
        headerDisplay
      />
    </div>
  );

  return (
    <div className="aggregates">
      {leftAggregrate}
      {centerAggregate}
      {rightAggregate}
    </div>
  );
};
export default Aggregates;
