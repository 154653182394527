import React from 'react';

const Tab = ({Image, name, children, ...props}) => (
  <div {...props} className='tab-view-tab'>
    <Image />
    {name}
    {children}
  </div>
);

export default Tab;