import React from 'react';
import { Progress } from 'antd';
import { FlowProfiles, Trains, WellParameters, Wells } from './components';
import Images from './Images';
import { hasAnyModifiedValues } from './func';

const mainContent = ({trains, wells, handleTrainsEdit, handleWellsEdit}) => {
  const countOpen = (assetList, modified) => {
    if(!assetList) return undefined;
    const ofTotal = assetList.length;
    const counted = ofTotal - assetList.filter(asset => ( asset.notAvailable )).length;
    return (
      <Progress
        className={`total-open-assets ${modified? 'modified' : ''}`}
        type="circle"
        percent={counted / ofTotal * 100}
        format={() => `${counted}/${ofTotal}`}
        width={50}
        strokeColor={`${counted === ofTotal ? '' : 'gold'}`}
        strokeWidth={7}
      />
    );
  };
  return [
    {
      tab: (
        <Trains.Tab Image={Images.TrainsIcon}>
          {countOpen(trains.items.map(asset => ({
              notAvailable: asset.staging.notAvailable != null
                ? asset.staging.notAvailable
                : asset.master.notAvailable
            })),
            hasAnyModifiedValues(trains.items)
          )}
        </Trains.Tab>
      ),
      children: <Trains {...trains} handleEdit={handleTrainsEdit} />
    },
    {
      tab: (
        <Wells.Tab Image={Images.WellsIcon}>
          {countOpen(
            wells.items.filter(well => (well.master.wellStatus === 'OPEN' && well.master.wellType === 'GP'))
              .map(asset => ({
                notAvailable: asset.staging.notAvailable != null
                  ? asset.staging.notAvailable
                  : asset.master.notAvailable
              }))
            , hasAnyModifiedValues(wells.items)
          )}
        </Wells.Tab>
      ),
      children: <Wells {...wells} handleEdit={handleWellsEdit} />
    },
    {
      tab: <FlowProfiles.Tab Image={Images.FlowProfilesIcon} />,
      children: <FlowProfiles {...wells} handleEdit={handleWellsEdit} />
    },
    {
      tab: <WellParameters.Tab Image={Images.WellParamIcon} />,
      children: <WellParameters {...wells} handleEdit={handleWellsEdit} />
    }
  ];
};

export default mainContent;