import { endOfNzDay, startOfNzDay } from '../time';
import configuration from '../configuration';
import { testConfig } from '../func';

export function filterTimeseries(timeSeries, filters) {
  return filters.map(filter => timeSeries.filter(filter)[0]);
}

export function filterSequences(sequences, filters) {
  return filters.map(filter => sequences.filter(filter)[0]);
}

export async function fetchSequences(client, assetIds) {
  return client.sequences
    .list({
      filter: {
        assetIds: [assetIds]
      }
    })
    .autoPagingToArray({ limit: Infinity });
}

export async function fetchOutage(client, assetId) {
  const config = configuration();
  testConfig(config);
  const outageFilterConfig = config.outage;
  const start = startOfNzDay().valueOf();
  const end = endOfNzDay().valueOf();
  const events = await client.events
    .list({
      filter: {
        ...outageFilterConfig,
        assetIds: [assetId],
        startTime: { min: start, max: end },
        endTime: { min: start, max: end }
      }
    })
    .autoPagingToArray({ limit: 1 });

  return events.length !== 0 ? events[0] : null;
}

const timeSeriesFetchBody = (timeSeries) => {
  const timeSeriesToFetch = [];
  timeSeries.forEach((ts, index) => {
    if (ts) {
      timeSeriesToFetch.push({
        index,
        id: ts.id
      });
    }
  });
  return timeSeriesToFetch;
}
async function fetchLatestDatapoints(client, timeSeriesToFetch, before = 'now') {
  const datapoints = await client.datapoints.retrieveLatest(
    timeSeriesToFetch.map(ts => ({ id: ts.id, before }))
  );
  return datapoints;
}
export async function fetchLatestDatapointValue(client, timeSeries, before = 'now') {
  const timeSeriesToFetch = timeSeriesFetchBody(timeSeries);
  const result = Array(timeSeries.length).fill(NaN);
  if (timeSeriesToFetch.length === 0) {
    return result;
  }
  const datapoints = await fetchLatestDatapoints(client, timeSeriesToFetch, before);
  timeSeriesToFetch.forEach((ts, i) => {
    let value = NaN;
    if (datapoints[i].datapoints && datapoints[i].datapoints.length === 1) {
      value = datapoints[i].datapoints[0].value;
    }
    result[ts.index] = value;
  });
  return result;
}

export async function fetchLatestDatapoint(client, timeSeries, before = 'now') {
  const timeSeriesToFetch = timeSeriesFetchBody(timeSeries);
  const result = Array(timeSeries.length).fill({value: NaN});
  if (timeSeriesToFetch.length === 0) {
    return result;
  }
  const datapoints = await fetchLatestDatapoints(client, timeSeriesToFetch, before);
  timeSeriesToFetch.forEach((ts, i) => {
    if (datapoints[i].datapoints && datapoints[i].datapoints.length === 1) {
      result[ts.index] = datapoints[i].datapoints[0];
    }
    else result[ts.index] = {value: NaN};
  });
  return result;
}

async function fetchLatestSequenceRows(client, sequences) {
  const result = Array(sequences.length).fill('');
  for (let index = 0; index < sequences.length; index += 1) {
    const { sequence, columnExternalId } = sequences[index];
    // eslint-disable-next-line no-await-in-loop
    const values = await client.sequences
      .retrieveRows({
        id: sequence.id,
        start: 1,
         end: 2,
        columns: [columnExternalId]
      })
      .autoPagingToArray({ limit: 1 });
    if (values.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      result[index] = values[0].values;
    }
  }
  return result;
}

export async function getDataFieldsValues(client, dataFields) {
  const result = {};
  const timeseriesToFetch = [];
  const sequencesToFetch = [];
  Object.keys(dataFields).forEach(dataField => {
    if (dataFields[dataField].ts) {
      timeseriesToFetch.push({
        dataField,
        ts: dataFields[dataField].ts
      });
    } else if (dataFields[dataField].sequence) {
      sequencesToFetch.push({
        dataField,
        sequence: dataFields[dataField].sequence,
        columnExternalId: dataFields[dataField].columnExternalId
      });
    } else {
      result[dataField] = NaN;
    }
  });
 
  const sequenceValues = await fetchLatestSequenceRows(
    client,
    sequencesToFetch
  );

  const datapoints = await fetchLatestDatapointValue(
    client,
    timeseriesToFetch.map(({ ts }) => ts)
  );
  timeseriesToFetch.forEach(({ dataField }, index) => {
    result[dataField] = datapoints[index];
  });
  sequencesToFetch.forEach(({ dataField }, index) => {
    result[dataField] = sequenceValues[index];
  });
  return result;
}
