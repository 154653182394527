import { renderName, WellTitle } from './WellTitle';

export default (dataIndex, onClick = ()=> undefined, width = '100px') => (
  {
    title: WellTitle({onClick}),
    dataIndex,
    fixed: 'left',
    width,
    render: renderName
  }
);
