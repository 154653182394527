import post from './post';
import configuration from '../configuration';

const postConfiguration = async (client, config, configExternalId = config.configExternalId) => {
  const path = configuration().restPath;
  const messageBody = {
    purpose: 'config',
    config,
    configExternalId
  };
  return post(path, client, JSON.parse(JSON.stringify(messageBody)));
};
export default postConfiguration;