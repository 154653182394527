import { FileExcelOutlined } from '@ant-design/icons';
import { Alert, Button, Popconfirm } from 'antd';
import { bool, func, node } from 'prop-types';
import React from 'react';
import Aggregates from './Aggregates';

const renderRefreshButton = (modifiedValues, resetAction, loading) => {
  const button = (
    <Button
      onClick={() => {
        if (!modifiedValues) {
          resetAction();
        }
      }}
      loading={loading}
      block={loading}
      disabled={loading}
    >
      <img src="/svgs/refresh.svg" alt="refresh icon" />
      Refresh
    </Button>
  );
  if (modifiedValues) {
    return (
      <Popconfirm
        title="You have unsaved changes. Want to refresh anyway?"
        onConfirm={resetAction}
      >
        {button}
      </Popconfirm>
    );
  }
  return button;
};

function AppHeader({
  inProgress,
  modifiedValues,
  aggregatesConfig,
  saveAction,
  refreshAction,
  beanUpProcedureURL,
}) {
  const loading = inProgress();
  const aggregates = aggregatesConfig();
  
  const manualQtMode = aggregates.manualQt.isModified();
  const runButtonText = () => {
    if (manualQtMode) return 'Test Run';
    return modifiedValues ? 'Save and run' : 'Run';
  };

  return (
    <header>
      <div className="header-logo">
        <div className="logo-and-name">
          <img src="/svgs/logo.svg" alt="OMV logo" width={60} />
          <h1>Maui</h1>
        </div>
        {beanUpProcedureURL && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={beanUpProcedureURL}
          >
            <FileExcelOutlined /> Kick Off and Production Strategy
          </a>
        )}
      </div>
      <Aggregates {...aggregatesConfig()} />
      <div className="button-group">
        <Button
          onClick={saveAction}
          loading={loading}
          block={loading}
          disabled={loading}
        >
          <img src="/svgs/folder.svg" alt="folder icon" />
          {runButtonText()}
        </Button>
        {renderRefreshButton(modifiedValues, refreshAction, loading)}
        {modifiedValues || manualQtMode ? (
          <Alert
            showIcon
            message="Parameters changed, calculation not updated"
            type="warning"
          />
        ) : null}
      </div>
    </header>
  );
}

export default AppHeader;

AppHeader.propTypes = {
  inProgress: func.isRequired,
  modifiedValues: bool.isRequired,
  aggregatesConfig: func.isRequired,
  saveAction: func.isRequired,
  refreshAction: func.isRequired,
  configButton: node,
};

AppHeader.defaultProps = {
  configButton: undefined,
};
