import React from 'react';
import filterPrepareAndSortWells from '../../func/filterPrepareAndSortWells';
import WellParametersTable from './WellParametersTable';
import mergeMasterStagingData from '../../func/mergeMasterStagingData';
import Tab from '../Tab';

function WellParameters(props) {
  const { items, displayAll } = props;
  const dataSource = filterPrepareAndSortWells(items, displayAll).map(mergeMasterStagingData);
  return <WellParametersTable {...props} dataSource={dataSource} />;
}

WellParameters.Tab = (props) => <Tab {...props} name='Well Parameters' />;

export default WellParameters;

