
const removeFromStagingWells = (wells, dataIndex) => {
  return wells.map( well => {
    const {staging, ...restWell} = well;
    const {[dataIndex]: removed, ...restStaging} = staging;
    return {
      ...restWell,
      staging: {
        ...restStaging
      }
    }
  })
};

export default removeFromStagingWells;