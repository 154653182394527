import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import CellArticle from '../WellParameters/CellArticle';
import configuration from '../../configuration';
import { defaultProps, propTypes } from './editableCellProps';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, isArticle, dataIndex, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      currentText: undefined
    };
    this.textRef = React.createRef();
    this.maxLength = configuration().maxTextLength;
  }

  componentDidMount() {
    const { dataIndex, record } = this.props;
    if(!dataIndex) return;
    let initialValue = record[dataIndex];
    if(initialValue === undefined || initialValue === null) initialValue = '';
    this.isStringValue = typeof initialValue === 'string';
    const currentText = (this.isStringValue && initialValue) || undefined;
    if(this.isStringValue)this.setState({currentText});
  }

  getCorrectValueType = (value) => this.isStringValue
    ? value
    : this.clampNumber(Number.parseFloat(value));

  setEditing = (editing) => {
    window.globalState.editing = editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  clampNumber = val => {
    if(val == null) return val;
    const {minNumber, maxNumber, record} = this.props;
    const min = minNumber(record);
    const max = maxNumber(record);
    if(!Number.isNaN(min) && min != null && val < min) {
      return min;
    }
    if(!Number.isNaN(max) && max != null && val > max) {
      return max;
    }
    return val;
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      const { dataIndex } = this.props;
      const value = this.getCorrectValueType(e.currentTarget.value);
      const { id } = record;
      if(!Number.isNaN(value) && value != null && value !== record[dataIndex]) {
        handleSave({ id, dataIndex, value });
      }
    });
    this.setEditing(false);
  };

  saveText = e => {
    const { record, handleSave, dataIndex } = this.props;
    const value = e.currentTarget.value || '';
    const { id } = record;
    if(value != null && value !== record[dataIndex]) {
      handleSave({ id, dataIndex, value });
    }
    this.setEditing(false);
  };

  setCurrentText = (e) => {
    this.setState({currentText: e.currentTarget.value})
  };

  renderCell = form => {
    this.form = form;
    const { children, record, dataIndex, minNumber, maxNumber} = this.props;
    const {isStringValue, maxLength} = this;
    const { editing } = this.state;
    const input = isStringValue
      ? (
        <Input.TextArea
          ref={node => (this.input = node)}
          onPressEnter={this.saveText} // remove this if you want to be able to press enter
          onBlur={this.saveText}
          maxLength={maxLength}
          onChange={this.setCurrentText}
          autoSize={{ minRows: 2, maxRows: 4 }}
        />
      )
      : (
        <InputNumber
          ref={node => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
          min={minNumber(record)}
          max={maxNumber(record)}
          step={0.1}
        />
      );

    const field = editing
      ? (
        <Form.Item>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: !isStringValue,
                message: 'Field is required.'
              }
            ],
            initialValue: record[dataIndex]
          })(input)}
        </Form.Item>
      )
      : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          className='editable-cell-value-wrap'
          onClick={() => this.setEditing(true)}
          role="button"
          tabIndex={0}
          ref={this.textRef}
        >
          {children}
        </div>
      );
    return field;
  };

  wrapIfTextArea(input) {
    const { isStringValue, maxLength } = this;
    const {currentText} = this.state;
    return isStringValue ? (
      <div className='text-area-wrapper'>
        {input}
        <p className='max-size-indicator'>{`${!currentText ? 0 : currentText.length} / ${maxLength}`}</p>
      </div>
    ) : input;
  }

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      isArticle,
      children,
      className,
      maxNumber,
      minNumber,
      ...restProps
    } = this.props;
    const isModified = record.edited.includes(dataIndex);
    const editableClass = `${dataIndex} ${editable ? ' modifiableField' : '' }${isModified ? ' modified' : ''}`;
    const classNames = isArticle
      ? `${className} cell-article ${editableClass}`
      : `${className} ${editableClass}`;
    const innerContent = editable
      ? this.wrapIfTextArea(
        <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
      )
      : children;
    const content = isArticle
      ? (
        <CellArticle title={title}>{innerContent}</CellArticle>
      )
      : innerContent;
    return (
      <td {...restProps} className={classNames}>
        { content }
      </td>
    );
  }
}
EditableCell.defaultProps = defaultProps;
EditableCell.propTypes = propTypes;

EditableCell.components = {
  body: {
    row: EditableFormRow,
    cell: EditableCell
  }
};
export default EditableCell;