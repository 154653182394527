import React, { Component } from 'react';
import { Table, Button } from 'antd';
import XLSX from 'xlsx';
import filterPrepareAndSortWells from '../../func/filterPrepareAndSortWells';
import mergeMasterStagingData from '../../func/mergeMasterStagingData';
import createVerticalView from './verticalView';
import createHorizontalView from './horizontalView';
import leftColumn from './leftColumn';
import Tab from '../Tab';
import { nzTimeFromTimestamp, nowLocalTime } from '../../time';
import { DownloadOutlined } from '@ant-design/icons';

class FlowProfiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verticalView: true,
    };
  }

  switchOrientation = () => {
    this.setState((prevState) => ({
      verticalView: !prevState.verticalView,
    }));
  };

  exportExcel = (dataSource) => {
    const halfHoursPerDay = 48;

    // Do want to display wellRate in current half hour
    const numProposed = dataSource[0].wellRates.length;
    const numHistorical = dataSource[0].historicalFlowRates.length - 1;
    const offset = numProposed + numHistorical - halfHoursPerDay;
    // Must create an array of arrays to represent the Excel table
    const table = [];

    // Create table header
    const tableColumns = ['time (NZST / GMT+12)'];
    dataSource.forEach((well) => tableColumns.push(well.name));
    table.push(tableColumns);

    // Iterate over each half hour in the day
    for (let i = 0; i < halfHoursPerDay; i++) {
      const row = [];
      // Fist add timestamp to row
      if (i < numHistorical) {
        row.push(
          nzTimeFromTimestamp(
            dataSource[0].historicalFlowRates[i]?.timestamp
          )?.format('DD MMM YYYY HH:mm')
        );
        // Then add flow rates
        dataSource.forEach((well) =>
          row.push(well.historicalFlowRates[i].average?.toFixed(2))
        );
      } else {
        row.push(
          nzTimeFromTimestamp(
            dataSource[0].wellRates[i - numHistorical + offset]?.rowNumber
          )?.format('DD MMM YYYY HH:mm')
        );
        dataSource.forEach((well) =>
          row.push(
            well.wellRates[i - numHistorical + offset]?.value?.toFixed(2)
          )
        );
      }
      if (
        dataSource[0].historicalFlowRates[i] &&
        !dataSource[0].historicalFlowRates[i + 1]
      ) {
        // Mark current time window
        row.push('◄ ◄ ◄');
      }
      // Finally, add row to table
      table.push(row);
    }

    // Create sheet from table
    const ws = XLSX.utils.aoa_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'flow profiles');

    // generate XLSX file and send to client
    XLSX.writeFile(
      wb,
      'flow_profiles_export_' +
        nowLocalTime().format('YYYY-MM-DD HH-mm-ss') +
        '.xlsx'
    );
  };

  render() {
    const { items, loading, displayAll } = this.props;
    const { verticalView } = this.state;
    const dataSource = filterPrepareAndSortWells(items, displayAll).map(
      mergeMasterStagingData
    );
    const [columns, flowProfile] = verticalView
      ? createVerticalView(dataSource)
      : createHorizontalView(dataSource);

    const tableColumns = [
      verticalView
        ? leftColumn('timestamp', this.switchOrientation, undefined)
        : leftColumn('name', this.switchOrientation, 2),
      ...columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
          }),
        };
      }),
    ];

    const y = 700;
    const display = (width) => (displayAll ? { x: width, y } : { x: 0, y });
    const scroll = verticalView ? display(3000) : { x: 5250, y };

    return (
      <div>
        {!loading && (
          <Button
            className="excel-export"
            type="primary"
            shape="circle"
            onClick={() => {
              this.exportExcel(dataSource);
            }}
          >
            <DownloadOutlined />
          </Button>
        )}
        <Table
          pagination={false}
          loading={loading}
          dataSource={flowProfile}
          columns={tableColumns}
          scroll={scroll}
          onRow={(record) => {
            if (this.state.verticalView && record.className) {
              return {
                className: record.className,
              };
            }

            return null;
          }}
        />
      </div>
    );
  }
}

FlowProfiles.Tab = (props) => <Tab {...props} name="Flow profiles" />;

export default FlowProfiles;
