import React from 'react';
import {Icon} from 'antd';

const Images = {};
Images.TrainsIcon = (props) => <img {...props} src='/svgs/trains.svg' alt='Trains icon' />;
Images.WellsIcon = (props) => <img {...props} src='/svgs/wells.svg' alt='Wells icon' />;
Images.FlowProfilesIcon = (props) => <img {...props} src='/svgs/flow_charts.svg' alt='Flow profiles icon' />;
Images.WellParamIcon = (props) => <img {...props} src='/svgs/PT_view.svg' alt='Well parameters icon' />;

Images.KeepFlowing = (props) => <img {...props} src='/svgs/keep_flowing.svg' alt='Warning symbol' />;

Images.ArrowUpRed = (props) => <img {...props} src='/svgs/arrow-up-red.svg' alt="Arrow up" />;
Images.ArrowUpGreen = (props) => <img {...props} src='/svgs/arrow-up-green.svg' alt="Arrow up" />;
Images.ArrowDownRed = (props) => <img {...props} src='/svgs/arrow-down-red.svg' alt="Arrow down" />;
Images.ArrowDownGreen = (props) => <img {...props} src='/svgs/arrow-down-green.svg' alt="Arrow down" />;

Images.Cogs = (props) => <Icon {...props} type="setting" theme="filled" />

export default Images;