import { Modal, Input, Alert } from 'antd';
import React from 'react';
import configuration from '../../configuration';
import { testConfig } from '../../func';
import { track } from '../../mixpanel';

const prettyFormatValue = (value) => JSON.stringify(value, null, '\u0020\u0020\u0020\u0020');

const filterChanges = (changes) => {
  const config = configuration();
  testConfig(config);
  const {modelParameters} = config;
  const actuallyChangedValues = Object.keys(changes).filter(value => {
    return JSON.stringify(modelParameters[value]) !== JSON.stringify(changes[value]);
  });
  if (!actuallyChangedValues.length) {
    return null;
  }
  const returnedChanges = {};
  actuallyChangedValues.forEach(value => {
    returnedChanges[value] = changes[value];
  });
  return returnedChanges;
};

class ConfigModal extends React.Component {
  constructor(props) {
    super(props);
    const config = configuration();
    testConfig(config);
    this.state = {
      rawConfig: prettyFormatValue(config.modelParameters),
      invalid: undefined,
      editedConfig: undefined,
      lastValid: undefined
    };
  };

  handleChange = (e) => {
    track("configuration_modal")
    this.setState({editedConfig: e.currentTarget.value})
  };

  validateContent = (e) => {
    const {value} = e.currentTarget;
    try {
      const parsedJson = JSON.parse(value);
      const json = prettyFormatValue(parsedJson);
      this.setState({ invalid: undefined, lastValid: json, editedConfig: json });
    } catch (error) {
      this.setState({ invalid: error.message });
    }
  };

  render() {
    const { okFunc, cancelFunc} = this.props;
    const { rawConfig, editedConfig, invalid, lastValid } = this.state;
    const handleCancel = () => cancelFunc();
    const handleOk = () => {
      try {
        const filtered = filterChanges(JSON.parse(editedConfig));
        return okFunc(filtered);
      }
      catch (error) {
        this.setState({invalid: error.message});
        return null;
      }
    };
    const textAreaClassName = invalid && 'redBoarderTextArea';
    const notChanged = lastValid === rawConfig;
    const notOk = !!invalid || notChanged ;
    return (
      <Modal
        visible
        title="Configuration"
        onOk={handleOk}
        okButtonProps={{disabled: notOk, ghost: notOk}}
        onCancel={handleCancel}
        className='configModal'
      >
        <Input.TextArea
          cols={40}
          className={textAreaClassName}
          value={editedConfig || rawConfig}
          autoSize={{ minRows: 16, maxRows: 19}}
          onChange={this.handleChange}
          onBlur={this.validateContent}
          onAbort={()=> this.setState(prev => ({editedConfig: prev.lastValid}), ()=> null)}
        />
        {invalid && <Alert message={`Invalid JSON: ${invalid}`} type='warning' />}
        {notChanged && <Alert message='Nothing is changed click Cancel' type='info' />}
      </Modal>
    );
  }
}

export default ConfigModal;