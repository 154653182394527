const parseManualResult = ({items: wells }, { column_headers: externalIds, rows }) => {
  const updatedWells = wells.map((well) => {
    const {staging, master, ...restWell} = well;
    const { externalId } = master;
    const idIndex = externalIds.findIndex(id => id === externalId);
    if (idIndex === -1) {
      return well;
    }
    const { wellRates, ...restStaging } = staging;
    const updatedWellRates = rows.map((row) => (
      {
        value: row.values[idIndex],
        rowNumber: row.rowNumber
      }
    ));
    return {...restWell, master, staging:{ ...restStaging, wellRates: updatedWellRates }};
  });
  return updatedWells;
};

export default parseManualResult;