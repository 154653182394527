
const getIdsForDataField = (asset, field) => {
  const dataField = asset.dataFields[field];
  const [resourceType] = Object.keys(dataField);
  const {externalId} = dataField[resourceType];
  const {columnExternalId} = dataField;
  return {
    externalId, columnExternalId, resourceType
  }
};

export default getIdsForDataField