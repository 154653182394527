import React from 'react';
import Images from '../Images';

function timeOfNextChange (time, value) {
  if(!time) {
    return(
      <span className='value-wrap timeOfNextChange low-opacity' />
    );
  }

  const indicator = value
    && (
      value >= 0
      ? (
        <div>
          <Images.ArrowUpRed className='smaller-arrow' />
          {value}
        </div>
      )
      : (
        <div>
          <Images.ArrowDownRed className='smaller-arrow' />
          {value * -1}
        </div>
      )
    );

  return(
    <span className='value-wrap timeOfNextChange'>
      {time}
      {indicator}
    </span>
  );
}
export default timeOfNextChange