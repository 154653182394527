import {
  endOfNzDay,
  startOfNzDay
} from '../time';
import configuration from '../configuration';

const wellParentExternalIds = ['NZ.MAU.MPA.WEL', 'NZ.MAU.MPB.WEL'];
const wellExternalIdFilters = [
  /^NZ\.MAU\.MPA\.WEL\.MA-\d{1,2}$/,
  /^NZ\.MAU\.MPB\.WEL\.MB-\d{1,2}$/
];

const trainParentExternalId = 'NZ.MAU.MPA';
const trainExternalIdFilter = /^NZ\.MAU\.MPA\.T(05|06|07|10|11)$/;

const fetchWellAssets = async (client) => {
  const parentAssets = await client.assets.retrieve(
    wellParentExternalIds.map(externalId => ({ externalId }))
  );

  const wellSubtrees = await Promise.all([
    await client.assets.list({ filter: { parentExternalIds: [parentAssets[0].externalId] } }), 
    await client.assets.list({ filter: { parentExternalIds: [parentAssets[1].externalId] } })
  ]);

  const wellAssets = [...wellSubtrees[0].items, ...wellSubtrees[1].items].filter(asset =>
    wellExternalIdFilters.some(regEx => asset.externalId.match(regEx))
  );
  return wellAssets;
}

const fetchTrainAssets = async (client) => {
  const [parentAsset] = await client.assets.retrieve([
    { externalId: trainParentExternalId }
  ]);
  const children = await client.assets.list({ filter: { parentExternalIds: [parentAsset.externalId] } });

  const trainAssets = children.items.filter(asset =>
    asset.externalId.match(trainExternalIdFilter)
  );
  return trainAssets;
};

const fetchOptimalWellRates = async (client) => {
  const {suffix} = configuration();
  const [sequence] = await client.sequences.retrieve([
    { externalId: `OPTIMAL_WELL_RATES${suffix || ''}` }
  ]);
  const start = startOfNzDay().valueOf();
  const end = endOfNzDay().valueOf();
  const result = await client.sequences
    .retrieveRows({
      id: sequence.id,
      start,
      end
    })
    .autoPagingToArray({ limit: Infinity });
  return result;
}

const fetchDataFromCdf = async (client) => {
  return Promise.all([
    fetchWellAssets(client),
    fetchTrainAssets(client),
    fetchOptimalWellRates(client)
  ]);
};
export default fetchDataFromCdf;