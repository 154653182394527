import post from './post';
import runBody from './runBody';
import configuration from '../configuration';
import { testConfig } from '../func';

const postSaveAndRun = async (client, { trains, wells, aggregates }) => {
  const config = configuration();
  testConfig(config);
  const path = config.restPath;
  const messageBody = {
    purpose: 'saverun',
    ...runBody({ trains, wells, aggregates, config })
  };
  return post(path, client, JSON.parse(JSON.stringify(messageBody)));
};
export default postSaveAndRun;