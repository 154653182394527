import React from 'react';
import {Input} from 'antd';
import HighlightedUnitValue from '../HighlightedUnitValue';
import { defaultProps, propTypes } from './unitValueProps';

const roundValue = (value, fallbackValue) => {
  let roundedValue = Number.parseFloat(value).toFixed(1);

  if(roundedValue === 'NaN' || Number.isNaN(roundedValue)) {
    roundedValue = fallbackValue;
  }
  return roundedValue;
};

function UnitValue(props) {
  const { description, value, unit, disabled, headerDisplay, highlighted, events, className, editing, fallbackValue } = props;
  const {onChange} = events;
  const classNames = `UnitValue ${className || 'basic'}`;

  const component = (inValue, trailingUnit = undefined) => (
    <div className={classNames}>
      <p>{description}</p>
      <Input
        value={inValue}
        disabled={disabled}
        onChange={onChange}
      />
      {trailingUnit && <p>{trailingUnit}</p>}
    </div>
  );
  const roundedValue = editing ? value : roundValue(value, fallbackValue);
  if (headerDisplay) {
    if (highlighted) {
      return (
        <HighlightedUnitValue
          {...props}
          value={roundedValue}
        />
      );
    }
    return component(`${roundedValue} ${unit}`);
  }
  return component(value, unit);
}

UnitValue.defaultProps = {
  ...defaultProps,
  events:{
    ...defaultProps.events,
    onChange: () => {}
  }
};
UnitValue.propTypes = propTypes;
export default UnitValue;
