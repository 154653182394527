import { minutesSinceMidnight, nowLocalTime, startOfNzDay } from '../../time';
import { calculateChangeIndication, getHistoricalFlowRate } from '../../func/rates';
import { renderName } from './WellTitle';
import { getRateFromTimestamp } from '../../func';
import renderFlowRate from './renderFlowRate';

const verticalView = (dataSource) => {
  const flowProfile = [];
  // add historical columns
  const halfHoursSinceMidnight = Math.floor(minutesSinceMidnight() / 30);
  for (let i = 0; i < halfHoursSinceMidnight; i += 1) {
    const startOfHalfHour = startOfNzDay()
      .clone()
      .add(i * 30, 'minutes');

    const flowRates = dataSource.map((source) => (getHistoricalFlowRate(
        source.historicalFlowRates,
        startOfHalfHour
      )
    ));

    flowProfile.push({
      key: `${startOfHalfHour.valueOf()}-${i}`,
      timestamp: startOfHalfHour.format('HH:mm'),
      flowRates
    });
  }

  const halfHoursBeforeNextMidnight = 24 * 2 - halfHoursSinceMidnight;
  const nowTime = nowLocalTime();
  const currentTimeWindow = nowTime.clone().subtract(nowTime.valueOf() % 1800000, 'ms');
  for (let i = 0; i < halfHoursBeforeNextMidnight; i += 1) {
    const prevFlowRates = flowProfile.length
      ? flowProfile[flowProfile.length - 1].flowRates
      : undefined;
    const mmt = startOfNzDay()
      .clone()
      .add(30 * halfHoursSinceMidnight + i * 30, 'minutes');

    const now = currentTimeWindow.valueOf() === mmt.valueOf();
    const flowRates = dataSource.map(dsi => ({
      average: getRateFromTimestamp(dsi.wellRates, mmt.valueOf()),
      current: now ? (dsi.currentRate) : undefined
    }));

    const cellOptions = {
      key: `${mmt.valueOf()}-${i}`,
      timestamp: mmt.format('HH:mm'),
      flowRates: prevFlowRates ? calculateChangeIndication(flowRates, prevFlowRates) : flowRates,
      className: now ? 'green-background' : undefined
    };

    flowProfile.push(cellOptions);
  }

  // Column headers
  const columns = dataSource.map((well, index) => (
    {
      title: renderName(well.name, well),
      dataIndex: `flowRates[${index}]`,
      render: renderFlowRate,
      className: 'flow-profile-value'
    }
  ));
  return [columns, flowProfile];
};

export default verticalView;