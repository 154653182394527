import React from 'react';
import { Table, Tag } from 'antd';
import renderUnit from '../renderUnit';
import CellArticle from './CellArticle';
import { getWellColor, wellRatesTreshold } from '../../func';
import { m3Mlnm3, m3hr, Sm3s } from '../units';
import tableColumn from '../cells/tableColumn';
import EditableCell from '../cells/EditableCell';
import CheckboxField from '../cells/CheckboxField';
import Text from '../Text'

function renderCheckboxField(dataIndex, handleEdit, title) {
  return (checked, asset) => {
    return (
      <CellArticle title={title}>
        <CheckboxField dataIndex={dataIndex} checked={checked} handleEdit={handleEdit} asset={asset} />
      </CellArticle>
    );
  };
}
const roundNumber = title => ( number => {
    const value = Number.isNaN(number)
      ? <span className="value-wrap" />
      : <span className="value-wrap">{number.toFixed(2)}</span>;
    return <CellArticle title={title}>{value}</CellArticle>
  });

const textRenderer = (text, asset) => <Text key={asset.id}>{text}</Text>;

function WellParametersTable(props) {
  const { dataSource, loading, handleEdit } = props;
  const isArticle = true;
  const columns = [
    // 1st row
    {
      title: <><>Well</></>,
      dataIndex: 'name',
      render: (text, well) => {
        return (
          <Tag color={getWellColor(well)}>
            {text}
          </Tag>
        );
      }
    },
    {
      dataIndex: 'keepFlowing',
      render: renderCheckboxField('keepFlowing', handleEdit, 'Keep flowing')
    },
    {
      dataIndex: 'notAvailable',
      render: renderCheckboxField('notAvailable', handleEdit, 'Not available')
    },
    {
      title: renderUnit('Target rate', Sm3s),
      dataIndex: 'targetRate',
      editable: true,
      render: textRenderer
    },
    {
      title: renderUnit('Min flow rate', Sm3s),
      dataIndex: 'minFlowRate',
      editable: true,
      render: textRenderer,
      handleEdit: ({ id, dataIndex, value }) =>
        handleEdit({ id, dataIndex, value }, (asset) => {
          const { maxLTRate, maxSTRate } = asset;
          return (maxLTRate < value)
            ? {
              maxLTRate: value,
              maxSTRate: maxSTRate < value ? value : maxSTRate
            } : {};
        })
    },
    {
      title: renderUnit('Max LT rate', Sm3s),
      dataIndex: 'maxLTRate',
      editable: true,
      render: textRenderer,
      minNumber: (well) => well.minFlowRate,
      handleEdit: ({ id, dataIndex, value }) =>
        handleEdit({ id, dataIndex, value }, (asset) => {
            const { maxSTRate } = asset;
            return (maxSTRate < value)
              ? {
                maxSTRate: value
              } : {};
        })
    },
    {
      title: renderUnit('Max ST rate', Sm3s),
      dataIndex: 'maxSTRate',
      editable: true,
      render: textRenderer,
      minNumber: (well) => well.maxLTRate
    },
    {
      dataIndex: 'STRateAllowed',
      render: renderCheckboxField('STRateAllowed', handleEdit,
        'ST rate allowed')
    },
    {
      title: renderUnit('CGR', m3Mlnm3),
      dataIndex: 'cgr',
      editable: true,
      render: textRenderer
    },
    {
      dataIndex: 'condensateRate',
      render: roundNumber(renderUnit('Cond. rate', m3hr))
    },
    {
      title: renderUnit('WGR', m3Mlnm3),
      dataIndex: 'wgr',
      editable: true,
      render: textRenderer
    },
    {
      dataIndex: 'waterRate',
      render: roundNumber(renderUnit('Water rate', m3hr))
    },
    // 2nd row
    {
      dataIndex: 'currentRate',
      render: (text, well) => {
        const { currentRate, proposedRate } = well;
        const diff = Math.abs(currentRate - proposedRate);
        if (Number.isNaN(diff)) {
          return roundNumber(renderUnit('Current rate', Sm3s))(text);
        }
        const validDiff = diff <= proposedRate * wellRatesTreshold();
        return (
          <CellArticle title={renderUnit('Current rate', Sm3s)}>
            <span className={`value-wrap ${validDiff ? 'green-value' : 'red-value'}`}>
              {currentRate.toFixed(2)}
            </span>
          </CellArticle>
        );
      }
    },
    {
      title: 'Instructions',
      dataIndex: 'instructions',
      editable: true,
      render: textRenderer
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      editable: true,
      render: textRenderer
    }
  ].map(v => ({...v, isArticle}));

  const tableColumns = columns.map(col => tableColumn(col, handleEdit));
  return (
    <Table
      className='wellparameters-table'
      showHeader={false}
      components={EditableCell.components}
      rowClassName={() => 'editable-row'}
      rowKey={record => record.id}
      pagination={false}
      loading={loading}
      dataSource={dataSource}
      columns={tableColumns}
    />
  );
}

export default WellParametersTable;

